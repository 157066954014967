import {
  BufferGeometry,
  CatmullRomCurve3,
  Mesh,
  Object3D,
  Quaternion,
  Vector3,
} from 'three';
import {
  EasingFunctions,
  customEaseIn,
  customEaseInOut,
  customEaseOut,
} from './utility/easing';
import { GeoJSONSource, LngLatLike, Map } from 'maplibre-gl';
import {
  acceleration,
  generate3DLine,
  getScaleFromZoom,
  getStraightLineBetweenTwoPoints,
  maxSpeed,
  setupPathSegments,
  zoomResolver,
} from './utility/utils';
import {
  AnimationConfig,
  AnimationController,
  LandTransportAnimationConfig,
} from './AnimationController';
import {
  Position,
  along,
  length,
  lineString,
  Feature,
  LineString,
  point,
  nearestPointOnLine,
  lineSlice,
} from '@turf/turf';
import {
  projectToWorld,
  unprojectFromWorld,
} from '~/CustomThreeJsWrapper/utility/utils';
import { AnimationTravelData, Path, PathSegment } from '~/utility/models';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { Config } from './MultiTransportAnimationController';
import { setAnimationState } from '~/components/ViewTravel/common';
import { toggleMapInteractivity } from '~/map/helpers';
import { mapOffset } from '~/components/ViewTravel/MobileFooter/BottomSheet';

/**
 * This class controls the animation of a car along a travel path on a map.
 * It inherits from the `AnimationController` class and provides car-specific functionalities.
 */
abstract class LandTransportController extends AnimationController {
  /**
   * A GeoJSON feature representing the path line string. It is used for visualization on the map.
   */
  pathLineString!: Feature<LineString>;

  /**
   * A factor used to scale the path for visual representation.
   */
  scaleFactor!: number;

  /**
   * Flag indicating whether to perform a fly-to animation when zooming out.
   */
  zoomOutFlyTo = false;
  startSegmentFirstFlyTo = false;
  startSegmentSecondFlyTo = false;
  startSegmentThirdFlyTo = false;
  startSegmentFourthFlyTo = false;
  MidSegmentFirstFlyTo = false;
  MidSegmentSecondFlyTo = false;
  EndSegmentFirstFlyTo = false;
  EndSegmentSecondFlyTo = false;

  pauseStartTime: number = 0;
  pauseDuration: number = 0;

  // ZoomIn Time
  zoomInStartTime: number = 0;
  zoomOutStartTime: number = 0;
  remainingZoomInTime: number = 0;

  // ZoomOut Time
  zoomInTimeTillPause: number = 0;
  zoomOutTimeTillPause: number = 0;
  remainingZoomOutTime: number = 0;

  middleSegmentTimeElapsed: number = 0;
  endSegmentTimeElapsed: number = 0;

  /**
   * Flag indicating whether to perform a fly-to animation when zooming in.
   */
  zoomInFlyTo = false;

  /**
   * An array of positions representing the dynamic path that may be changing over time.
   */
  dynamicPath: Position[] = [];

  /**
   * Time elapsed since the start animation (seconds).
   */
  seTime = 0;

  /**
   * Total elapsed time (seconds).
   */
  tTime = 0;

  /**
   * Time spent in the middle segment (seconds).
   */
  mTime = 0;

  /**
   * Time elapsed since entering the middle segment (seconds).
   */
  tseTime = 0;

  /**
   * Flag indicating whether the animation has entered the middle segment.
   */
  enteredMiddleSegment = false;

  /**
   * Time at which the middle segment was entered (seconds).
   */
  middleSegmentEnterTime = 0;

  /**
   * Percentage distance traveled within the start and end segments (0-1).
   */
  percentageDistanceForSESegment = 0;
  /**
   * Coefficient for speeding up the zoom-in/zoom-out, higher - faster (number)
   */
  zoomSpeedCoefficient = 6;
  /**
   * Flag indicating whether the animation has entered the end segment.
   */
  enteredEndSegment = false;

  /**
   * Time at which the end segment was entered (seconds).
   */
  endSegmentEnterTime = 0;

  /**
   * Time elapsed up to the start of the middle segment with the middle segment's velocity (seconds).
   */
  secondsUptoStartOfMiddleWithMVelocity!: number;

  /**
   * Time elapsed up to the start of the middle segment using the default velocity (seconds).
   */
  secondsUptoStartOfMiddleSegmentWithDefaultVelocity = 0;

  duration = 0;

  /**
   * Index of the last point used in the dynamic path.
   */
  lastPathIndex: number = 0;

  /**
   * Time percentage for the start and end segment
   */
  seDurationPercentage = 0.25;

  /**
   * Time percentage for the start and end segment
   */
  middleSegmentDurationPercentage = 0.5;

  path: Path = undefined as any;
  indexPath = 0;
  currentTime = 0;
  currentSegment: PathSegment = {
    time: 0,
    distance: 0,
    intervals: [],
    lineString: [],
    screenTime: 0,
    realWorldDistance: 0,
    sourceID: '',
  };
  totalDistance = 0;
  totalTime: number = 0;

  abstract onAnimationEnded(): void;

  abstract startAnimation(): void;

  /**
   * Constructor for a base animation controller class. This class provides common functionalities
   * for animating objects along a path on a map. Specific implementations for LandTransportController
   * and PlaneAnimationController will extend this class.
   *
   * @param map A reference to the Maplibre map instance
   * @param index Index of the current travel segment
   * @param model A reference to the GLTF object to be animated
   * @param tb A reference to the Three.js wrapper class
   */
  constructor(map: Map, index: number, tb: CustomThreeJSWrapper) {
    super();
    if (!map) return;
    this.map = map;
    this.tb = tb;
    this.index = index;
    this.pathGeometry = new BufferGeometry();
  }

  /**
   * Starts the car animation along the travel path.
   *
   * @param animationConfig
   * Configuration object for the car animation
   * (specific to LandTransportController)
   *
   * @remarks
   * This function overrides the base class's `startAnimation` method.
   * It performs car-specific setup tasks in addition to the common
   * animation setup.
   */
  setupAnimation(animationConfig: LandTransportAnimationConfig) {
    this.animationConfig = animationConfig;
    console.log('animationConfig:', this.animationConfig);

    this.lastPathIndex = 0;
    this.setupPath();

    this.setupAnimationTime();

    // Setup model for Line Animation with Model
    if (this.model) this.setupModelForAnimation();

    this.setLineLayerAndSources();

    this.startAnimation();
    this.cameraZoom = zoomResolver(this.cameraZoom, this.streetLevelZoom);
  }

  setLineLayerAndSources() {
    console.log('setLineLayerAndSources() called');
    this.addAnimationLineLayers();
  }

  /**
   * Resets all GeoJSON data sources used for route and buffered lines associated with animation segments.
   * This function iterates through the animation travel data and clears the GeoJSON data for each segment's route and buffered line source.
   * This is typically used to clear previous route or buffer visualizations before starting a new animation.
   */
  resetGeoJSONSources(path: Position[]): void {
    if (this.path) {
      this.addTravelLayer(this.path.start.sourceID, []);
      this.addTravelLayer(this.path.middle.sourceID, []);
      this.addTravelLayer(this.path.end.sourceID, []);
    }
  }

  /**
   * Updates the line string geometry of a route on the map. This function is
   * used internally by the TravelAnimation class to update the visual representation
   * of the travel path as the animation progresses.
   *
   * @param updatedCoordinates An array of Position objects representing the new coordinates
   *                           for the line string
   *
   * @remarks
   *   - This function assumes a GeoJSON source named `'route' + this.index` exists
   *     on the map, where `this.index` is a property of the calling object.
   *   - It updates the source's data with a new GeoJSON LineString geometry created
   *     from the provided `updatedCoordinates`.
   *   - While the commented-out section suggests buffer functionality, it's not
   *     included in the provided code.
   */
  updateLineString(updatedCoordinates: Position[]) {
    if (updatedCoordinates.length >= 2) {
      const source = this.map.getSource(
        this.currentSegment.sourceID,
      ) as GeoJSONSource;
      const line = lineString(updatedCoordinates);
      if (source) source.setData(line);
    }
  }

  addTravelLayer(id: any, obj: Position[]) {
    if (!this.map.getSource(id)) {
      this.map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: obj,
          },
        },
      });

      this.map.addLayer({
        id: id,
        type: 'line',
        source: id,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FE7138',
          'line-width': 7,
        },
      });
    } else {
      const source = this.map.getSource(id) as GeoJSONSource;
      source.setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: obj,
        },
      });
    }
  }

  /**
   * Adds animation line layers to the map. These layers represent the travel route for each animation segment.
   *
   * This function checks if the map style is loaded and the source with the ID 'route{index}' (where `{index}` is the current segment index)
   * doesn't exist. If the source doesn't exist, it creates a new GeoJSON source with the route data.
   *
   * If the source exists and the dynamic path (which represents the animated travel path) has data, the function updates the source's data
   * with the latest `lineString` geometry created from the `dynamicPath`.
   *
   * Finally, the function checks if the layer named 'custom-threejs-layer' exists and moves it to the top of the layer stack if so.
   * This ensures that the animation lines are rendered on top of other map elements.
   */
  addAnimationLineLayers() {
    if (this.path) {
      this.addTravelLayer(this.path.start.sourceID, []);
      this.addTravelLayer(this.path.middle.sourceID, []);
      this.addTravelLayer(this.path.end.sourceID, []);
    }

    // if (!this.map.getSource(id)) {
    //   this.addTravelLayer(id, this.dynamicPath);
    // } else if (this.map.getSource(id) && this.dynamicPath.length > 0) {
    //   const source = this.map.getSource(id) as GeoJSONSource;
    //   source.setData(lineString(this.dynamicPath));
    // }

    if (this.map.getLayer('custom-threejs-layer'))
      this.map.moveLayer('custom-threejs-layer');
  }

  getSanitizedPathSegments(path: Position[]) {
    if (setupPathSegments(path).intervals.length <= 2) {
      const { coordinates } = getStraightLineBetweenTwoPoints(
        path[0],
        path[path.length - 1],
      );
      // const line = lineString(coordinates);
      return setupPathSegments(coordinates);
    } else return setupPathSegments(path);
  }

  generatePath(path: Position[]): Path {
    const line = lineString(path);
    this.totalDistance = length(line, { units: 'kilometers' });


    const baseSpeedFactor = 0.0001; // Base speed in km/ms (adjust if needed)
    const calculatedSpeed = this.totalDistance * baseSpeedFactor;


    this.duration = this.totalDistance / calculatedSpeed; // in milliseconds


    const seKMs = Math.min(0.25 * this.totalDistance, 1);
    const startDistance = seKMs;
    const middleDistance = this.totalDistance - 2 * seKMs;

    // Create start segment
    const startSegmentFinalPoint = along(line, startDistance, {
      units: 'kilometers',
    });
    const startSegmentLine = lineSlice(point(path[0]), startSegmentFinalPoint, line);

    // Create middle segment
    const middleSegmentFinalPoint = along(line, startDistance + middleDistance, {
      units: 'kilometers',
    });
    const middleSegmentLine = lineSlice(startSegmentFinalPoint, middleSegmentFinalPoint, line);


    const endSegmentLine = lineSlice(middleSegmentFinalPoint, point(path[path.length - 1]), line);

    const speedScale = this.travelSegment.travelSegmentConfig.animationSpeed;

    const startDuration = (this.duration * this.seDurationPercentage) / speedScale;
    const middleDuration = (this.duration * this.middleSegmentDurationPercentage) / speedScale;
    const endDuration = (this.duration * this.seDurationPercentage) / speedScale;

    const startPath: PathSegment = {
      ...this.getSanitizedPathSegments(startSegmentLine.geometry.coordinates),
      lineString: startSegmentLine.geometry.coordinates,
      screenTime: startDuration / 1000,
      realWorldDistance: startDistance,
      sourceID: 'start' + this.index,
    };

    const middlePath: PathSegment = {
      ...this.getSanitizedPathSegments(middleSegmentLine.geometry.coordinates),
      lineString: middleSegmentLine.geometry.coordinates,
      screenTime: middleDuration / 1000,
      realWorldDistance: middleDistance,
      sourceID: 'middle' + this.index,
    };

    const endPath: PathSegment = {
      ...this.getSanitizedPathSegments(endSegmentLine.geometry.coordinates),
      lineString: endSegmentLine.geometry.coordinates,
      screenTime: endDuration / 1000,
      realWorldDistance: startDistance,
      sourceID: 'end' + this.index,
    };


    this.totalTime = startPath.screenTime + middlePath.screenTime + endPath.screenTime;

    return {
      start: startPath,
      middle: middlePath,
      end: endPath,
    };
  }


  /**
   * Sets up the path for the travel animation, including generating a 3D line mesh,
   * initializing the dynamic path array, and adding the path mesh to the scene.
   *
   * This function is called internally by the `TravelAnimation` class.
   */
  setupPath() {
    const travelSegment = this.travelSegment;

    /**
     * Generates a 3D line mesh representing the travel path.
     *
     * It takes the travel segment's decoded path, selected transport mode, and a reference
     * to the Three.js wrapper (`this.tb`) as arguments, and returns a mesh object containing
     * the path geometry and material.
     */
    const pathMesh = generate3DLine(
      travelSegment.decodedPath.path,
      travelSegment.selectedTransport,
      this.tb,
    );

    // Fill whole dynamicPath array with first coordinate of actual path
    if (travelSegment.decodedPath.path.length > 0) {
      this.path = this.generatePath(travelSegment.decodedPath.path);

      this.currentSegment = this.path.start;
      this.dynamicPath = new Array(this.currentSegment.intervals.length).fill(
        this.currentSegment.lineString[0],
      );
    }

    /**
     * Store references to the path curve, material, and geometry for later use.
     *
     * The path curve is a `CatmullRomCurve3` object used for animation purposes.
     * The material defines the visual properties of the path mesh.
     * The geometry represents the 3D shape of the path.
     */
    this.pathCurve = pathMesh.pathCurve as CatmullRomCurve3;
    this.material = pathMesh?.material;
    this.pathGeometry = pathMesh?.pathGeometry as BufferGeometry;

    /**
     * Create a GeoJSON LineString object from the decoded path.
     *
     * This LineString be used for visualization of path with the map.
     */
    this.pathLineString = lineString(travelSegment.decodedPath.path);

    /**
     * Create a Mesh object from the path geometry and material.
     *
     * The Mesh object represents the visual representation of the travel path in 3D space.
     */
    this.pathMesh = new Mesh(this.pathGeometry, this.material);

    /**
     * Add the path mesh to the Three.js scene.
     *
     * This ensures the path is visible during rendering.
     */
    this.tb.add(this.pathMesh);
  }

  /**
   * Gets the zoom level at which the travel animation should start.
   *
   * This function returns a pre-defined zoom level suitable for street-level views,
   * which is typically used at the beginning of the travel animation.
   *
   * @returns The zoom level for the animation start (e.g., street level zoom)
   */
  getAnimationStartZoom() {
    return this.streetLevelZoom;
  }

  /**
   * Clears the animation data and resets the GeoJSON sources on the map.
   *
   * This function is called when the animation needs to be restarted or cleaned up.
   * It removes any existing animation data and resets the GeoJSON sources used to render the travel paths
   * on the map.
   */
  clean() {
    this.resetGeoJSONSources([]);
    if (this.path) this.currentSegment = this.path.start;
    this.indexPath = 0;
    this.currentTime = 0;

    console.log('reset');

    this.startSegmentFirstFlyTo = false;
    this.startSegmentSecondFlyTo = false;
    this.startSegmentThirdFlyTo = false;
    this.startSegmentFourthFlyTo = false;
    this.MidSegmentFirstFlyTo = false;
    this.MidSegmentSecondFlyTo = false;
    this.EndSegmentFirstFlyTo = false;
    this.EndSegmentSecondFlyTo = false;

    this.zoomInFlyTo = false;
    this.zoomOutFlyTo = false;
    this.timeElapsedTillPause = 0;
    this.remainingZoomOutTime = this.ZOOM_TRANSITION_DURATION;
    this.remainingZoomInTime = this.ZOOM_TRANSITION_DURATION;
    this.zoomInTimeTillPause = 0;
    this.zoomOutTimeTillPause = 0;
    this.middleSegmentTimeElapsed = 0;
    this.endSegmentTimeElapsed = 0;
  }

  onPause() {
    this.timeElapsedTillPause = this.timeElapsed;
    const now = performance.now();

    if (this.zoomInFlyTo) {
      this.zoomInTimeTillPause = now - this.zoomInStartTime;
    }

    if (this.zoomOutFlyTo) {
      this.zoomOutTimeTillPause = now - this.zoomOutStartTime;
    }
  }

  onPlay() {
    const now = performance.now();

    this.animationStartTime = now - this.timeElapsedTillPause;

    if (this.enteredMiddleSegment) {
      this.enteredMiddleSegment = false;
    }

    if (this.enteredEndSegment) {
      this.enteredEndSegment = false;
    }

    if (this.zoomInFlyTo) {
      const startSegmentHalfTime =
        (this.path.start.screenTime -
          this.path.start.screenTime / this.zoomSpeedCoefficient) *
        1000;
      this.remainingZoomInTime =
        startSegmentHalfTime - this.zoomInTimeTillPause;
      this.zoomInFlyTo = false;
    }

    if (this.zoomOutFlyTo) {
      const startSegmentHalfTime =
        (this.path.end.screenTime / this.zoomSpeedCoefficient) * 1000;

      this.remainingZoomOutTime =
        startSegmentHalfTime - this.zoomOutTimeTillPause;
      this.zoomOutFlyTo = false;
    }
  }

  private lerp = (min: number, max: number, t: number): number => {
    return min + (max - min) * t;
  };

  private calculateTime = (distance: number, maxDuration: number): number => {
    const minTime = maxDuration < 5000 ? maxDuration : 5000; // in seconds
    const maxTime = maxDuration; // in seconds
    const minDistance = distance > 1 ? 1 : distance; // in km
    const maxDistance = 4; // in km

    // Normalize the distance to a range of 0 to 1
    const t = (distance - minDistance) / (maxDistance - minDistance);

    // Clamp t between 0 and 1
    const clampedT = Math.max(0, Math.min(1, t));

    // Calculate the interpolated time
    return this.lerp(minTime, maxTime, clampedT);
  };

  /**
   * Sets up animation timing parameters based on travel segment data and animation configuration.
   *
   * This function calculates various time intervals, velocities, and distances used during the
   * animation of a travel segment. It considers factors like the total travel distance, animation
   * duration, animation speed, and HQ distance (a predefined distance used for zoom transitions).
   *
   * @param travelSegment The travel segment data for which to set up animation timing
   * @param animationConfig The animation configuration object containing properties like duration
   */
  setupAnimationTime() {
    // (this.animationConfig as LandTransportAnimationConfig).duration =
    //   (this.path.start.screenTime +
    //     this.path.middle.screenTime +
    //     this.path.end.screenTime) *
    //   1000;
    this.ZOOM_TRANSITION_DURATION =
      (this.path.start.screenTime / this.zoomSpeedCoefficient) * 1000;
    this.remainingZoomInTime = this.remainingZoomOutTime =
      this.ZOOM_TRANSITION_DURATION;
  }
  /**
   * Performs linear interpolation between two points based on a given value.
   *
   * This function  takes a value and interpolates it between two
   * pre-defined points (x1, y1) and (x2, y2). The resulting value falls
   * on a line connecting these points.
   *
   * @param value The value to be interpolated
   * @returns The interpolated value
   */
  interpolate(value: number) {
    const x1 = value < 100 ? 15 : 100; // Min distance
    const y1 = value < 100 ? 1 : 2; // Max output
    const x2 = 6000; // Max distance
    const y2 = 5; // Min output

    return y1 + ((value - x1) * (y2 - y1)) / (x2 - x1);
  }

  /**
   * Updates the camera position based on the animation progress and travel segment configuration.
   *
   * This function adjusts the map's center, bearing, pitch, and zoom level based on the
   * current animation time (represented by `easedTimeProgress`) and the travel segment data.
   * It considers three main stages of the animation:
   *
   * 1. **Start of the segment (easedTimeProgress <= percentageDistanceForSESegment):**
   *    - Flies the map to the starting position of the travel segment with the specified
   *      street level zoom, animation speed, and linear easing.
   *
   * 2. **Middle of the segment (percentageDistanceForSESegment < easedTimeProgress < 1 - percentageDistanceForSESegment):**
   *    - If the zoom level is close enough to the street level zoom, it flies the map
   *      to the starting position again. Otherwise, it calculates a position along the path
   *      based on `easedTimeProgress` and initiates a fly-to animation with the street level
   *      zoom and a set duration.
   *
   * 3. **End of the segment (easedTimeProgress > 1 - percentageDistanceForSESegment):**
   *    - If the current zoom level is close enough to the calculated zoom level, it flies
   *      the map to the ending position of the travel segment. Otherwise, it calculates a
   *      position along the path based on `easedTimeProgress` and initiates a fly-to animation
   *      with the current zoom level and a set duration.
   *
   * @param timeProgress Total time elapsed since the start of the animation per 35 seconds
   * @param modelLngLatPos The LngLat coordinates of the 3D model representing the travel vehicle
   * @param zoom The current zoom level of the map
   */

  flyToStartSegment(
    timeProgress: number,
    zoomOutTiming: number,
    modelLngLatPos: LngLatLike,
  ) {
    if (timeProgress < zoomOutTiming) {
      console.log('Start segment flyTo per frame - First half');
      this.map.flyTo({
        center: modelLngLatPos,
        bearing: this.mapBearing,
        pitch: this.mapPitch,
        zoom: this.streetLevelZoom,
        duration: 100,
        easing: EasingFunctions.linear,
        essential: true,
        offset: mapOffset.value,
      });
    } else if (this.totalDistance > 1) {
      this.zoomOutCameraTransition();
    }
  }

  flyToMiddleSegment(modelLngLatPos: LngLatLike) {
    // console.log('Mid Segment per frame');
    this.map.flyTo({
      center: modelLngLatPos,
      bearing: this.mapBearing,
      pitch: this.mapPitch,
      zoom: this.totalDistance > 1 ? this.cameraZoom : this.streetLevelZoom,
      duration: 100,
      easing: EasingFunctions.linear,
      essential: true,
      offset: mapOffset.value,
    });
  }

  flyToEndSegment(
    timeProgress: number,
    zoomInTiming: number,
    midSegmentTotalTime: number,
    modelLngLatPos: LngLatLike,
  ) {
    if (
      timeProgress > midSegmentTotalTime &&
      timeProgress < midSegmentTotalTime + zoomInTiming &&
      this.totalDistance > 1
    ) {
      this.zoomInCameraTransition(zoomInTiming);
    } else if (timeProgress >= midSegmentTotalTime + zoomInTiming) {
      console.log('End segment flyTo per frame - Second half');
      this.map.flyTo({
        center: modelLngLatPos,
        bearing: this.mapBearing,
        pitch: this.mapPitch,
        zoom: this.streetLevelZoom,
        duration: 100,
        easing: EasingFunctions.linear,
        essential: true,
        offset: mapOffset.value,
      });
    }
  }

  zoomInCameraTransition(zoomInTiming: number) {
    if (!this.zoomInFlyTo) {
      console.log('Single flyTo End Segment - First half');
      this.zoomInFlyTo = true;
      this.zoomInStartTime = performance.now() - this.zoomInTimeTillPause;
      const midOfEndSegment = unprojectFromWorld(
        this.getPrecomputedPositionAtTime(zoomInTiming, this.path.end).position,
      );
      this.map.flyTo({
        center: midOfEndSegment as LngLatLike,
        bearing: this.mapBearing,
        pitch: this.mapPitch,
        zoom: this.streetLevelZoom,
        duration: this.remainingZoomInTime,
        easing: EasingFunctions.linear,
        essential: true,
        offset: mapOffset.value,
      });
    }
  }

  zoomOutCameraTransition() {
    if (!this.zoomOutFlyTo) {
      console.log('Single flyTo Start Segment - Second half');
      this.zoomOutFlyTo = true;
      this.zoomOutStartTime = performance.now() - this.zoomOutTimeTillPause;
      this.map.flyTo({
        center: this.path.start.lineString[
          this.path.start.lineString.length - 1
        ] as LngLatLike,
        bearing: this.mapBearing,
        pitch: this.mapPitch,
        zoom: this.cameraZoom,
        duration: this.remainingZoomOutTime,
        easing: EasingFunctions.linear,
        essential: true,
        offset: mapOffset.value,
      });
    }
  }

  updateCamera(timeProgress: number, modelLngLatPos: LngLatLike) {
    const startSegmentTotalTime = this.path.start.screenTime;
    const midSegmentTotalTime =
      this.path.middle.screenTime + startSegmentTotalTime;
    const endSegmentTotalTime = this.path.end.screenTime;
    if (this.currentSegment === this.path.start) {
      // Start 1
      if (timeProgress > 0 && !this.startSegmentFirstFlyTo) {
        this.startSegmentFirstFlyTo = true;
        this.map.flyTo({
          center: unprojectFromWorld(
            this.getPrecomputedPositionAtTime(
              startSegmentTotalTime * 0.35,
              this.path.start,
            ).position,
          ),
          bearing: this.mapBearing,
          pitch: this.mapPitch,
          zoom: this.streetLevelZoom,
          duration: startSegmentTotalTime * 1000 * 0.15,
          easing: EasingFunctions.easeInCubic,
          essential: true,
          offset: mapOffset.value,
        });
        toggleMapInteractivity(this.map, false);
      }
      // Start 2
      else if (
        timeProgress > startSegmentTotalTime * 0.35 &&
        !this.startSegmentSecondFlyTo
      ) {
        this.startSegmentSecondFlyTo = true;
        this.map.flyTo({
          center: unprojectFromWorld(
            this.getPrecomputedPositionAtTime(
              startSegmentTotalTime * 0.7,
              this.path.start,
            ).position,
          ),
          bearing: this.mapBearing,
          pitch: this.mapPitch,
          zoom: this.streetLevelZoom,
          duration: startSegmentTotalTime * 1000 * 0.15,
          easing: EasingFunctions.easeInCubic,
          essential: true,
        });
        toggleMapInteractivity(this.map, false);
      }
      // Start 3
      else if (
        timeProgress > startSegmentTotalTime * 0.75 &&
        !this.MidSegmentFirstFlyTo
      ) {
        this.MidSegmentFirstFlyTo = true;
        this.map.flyTo({
          center: unprojectFromWorld(this.path.middle.intervals[0].start),
          bearing: this.mapBearing,
          pitch: this.mapPitch,
          zoom: this.cameraZoom,
          duration: midSegmentTotalTime * 1000 * 0.15,
          easing: EasingFunctions.easeOutCubic,
          essential: true,
          offset: mapOffset.value,
        });
        toggleMapInteractivity(this.map, true);
      }
    }
    // Middle 1
    if (this.currentSegment === this.path.middle) {
      if (
        timeProgress >
        startSegmentTotalTime + this.path.middle.screenTime * 0.1 &&
        !this.MidSegmentSecondFlyTo
      ) {
        this.MidSegmentSecondFlyTo = true;
        this.map.flyTo({
          center: unprojectFromWorld(
            this.path.middle.intervals[this.path.middle.intervals.length - 1]
              .end,
          ),
          bearing: this.mapBearing,
          pitch: this.mapPitch,
          zoom: this.cameraZoom,
          duration: midSegmentTotalTime * 1000 * 0.5,
          easing: EasingFunctions.linear,
          essential: true,
          offset: mapOffset.value,
        });
        toggleMapInteractivity(this.map, true);
      }
    } else if (this.currentSegment === this.path.end) {
      // End 1
      if (timeProgress > midSegmentTotalTime && !this.EndSegmentFirstFlyTo) {
        this.EndSegmentFirstFlyTo = true;
        this.map.flyTo({
          center: unprojectFromWorld(
            this.getPrecomputedPositionAtTime(
              endSegmentTotalTime * 0.5,
              this.path.end,
            ).position,
          ),
          bearing: this.mapBearing,
          pitch: this.mapPitch,
          zoom: this.streetLevelZoom,
          duration: endSegmentTotalTime * 1000 * 0.15,
          easing: EasingFunctions.linear,
          essential: true,
          offset: mapOffset.value,
        });
        toggleMapInteractivity(this.map, true);
      }
      //End 2
      if (
        timeProgress > midSegmentTotalTime + startSegmentTotalTime * 0.5 &&
        !this.EndSegmentSecondFlyTo
      ) {
        this.EndSegmentSecondFlyTo = true;
        this.map.flyTo({
          center: unprojectFromWorld(
            this.path.end.intervals[this.path.end.intervals.length - 1].end,
          ),
          bearing: this.mapBearing,
          pitch: this.mapPitch,
          zoom: this.streetLevelZoom,
          duration: endSegmentTotalTime * 1000 * 0.15,
          easing: EasingFunctions.linear,
          essential: true,
          offset: mapOffset.value,
        });
        toggleMapInteractivity(this.map, true);
      }
    }
  }

  /**
   * Updates the scale of the 3D model based on the current map zoom level.
   *
   * @param zoom The current zoom level of the map
   */
  updateModelScale(zoom: number): void {
    /**
     * Calculates the desired scale factor based on the zoom level using a custom function (implementation not provided)
     */
    const desiredScale = getScaleFromZoom(zoom);

    /**
     * Combines the desired scale factor with the user-defined model scale from the travel segment configuration
     */
    let modelScale =
      desiredScale * this.travelSegment.travelSegmentConfig.modelScale;

    /**
     * Creates a temporary THREE.Vector3 object to hold the new scale values
     */
    let tempLerpVec = new Vector3(modelScale, modelScale, modelScale);

    /**
     * Updates the scale of the 3D model using the calculated scale values
     */
    this.model?.scale.copy(tempLerpVec);
  }

  getPrecomputedPosition(delta: number) {
    if (this.indexPath >= this.currentSegment.intervals.length) {
      console.log(
        'segment at start:',
        JSON.parse(JSON.stringify(this.currentSegment)),
      );

      this.indexPath = 0;
      this.currentTime = 0;
      if (this.currentSegment === this.path.start)
        this.currentSegment = this.path.middle;
      else if (this.currentSegment === this.path.middle)
        this.currentSegment = this.path.end;
      else if (this.currentSegment === this.path.end) {
        this.isAnimationExpired = true;
        console.log('ended');
        // must return on end
        return;
      }

      this.dynamicPath = new Array(this.currentSegment.intervals.length).fill(
        this.currentSegment.lineString[0],
      );
    }

    let step = this.currentSegment.intervals[this.indexPath];
    const scaledDelta =
      delta * (this.currentSegment.time / this.currentSegment.screenTime);
    this.currentTime += scaledDelta;
    const position = new Vector3();
    const lookAtPosition = new Vector3();

    let exit = false;
    while (!exit) {
      const time = Math.min(step.timeToMaxSpeed, this.currentTime);
      const distanceAcc =
        time * step.startSpeed + 0.5 * acceleration * time ** 2;
      const distanceMaxSpeed =
        Math.max(0, this.currentTime - step.timeToMaxSpeed) * maxSpeed;
      const dist = distanceAcc + distanceMaxSpeed;

      const alpha = dist / step.distance;
      position.lerpVectors(step.start, step.end, Math.min(1, alpha));
      // Lerp lookAtPosition a little forward than current position
      lookAtPosition.lerpVectors(
        step.start,
        step.end,
        Math.min(1, alpha + 0.1),
      );

      const coord = unprojectFromWorld(position);
      if (this.indexPath < this.dynamicPath.length - 1) {
        this.dynamicPath.fill(coord as any, this.indexPath + 1);
      } else {
        this.dynamicPath.fill(coord as any, this.indexPath);
      }
      if (alpha < 1) exit = true;
      else {
        this.currentTime -= step.time;
        this.indexPath++;
        if (this.indexPath < this.currentSegment.intervals.length) {
          step = this.currentSegment.intervals[this.indexPath];
          const pathSegmentStartCoord = unprojectFromWorld(
            this.currentSegment.intervals[this.indexPath].start,
          );
          this.dynamicPath[this.indexPath] = pathSegmentStartCoord as any;
        } else {
          exit = true;
        }
      }
    }

    return {
      position,
      lookAtPosition,
    };
  }

  getPrecomputedPositionAtTime(elapsedTime: number, segment: PathSegment) {
    let indexPath = 0;
    let currentTime = 0;
    let step = segment.intervals[indexPath];
    const scaledDelta = elapsedTime * (segment.time / segment.screenTime);
    currentTime += scaledDelta;

    const position = new Vector3();
    const lookAtPosition = new Vector3();

    let exit = false;
    while (!exit) {
      const time = Math.min(step.timeToMaxSpeed, currentTime);
      const distanceAcc =
        time * step.startSpeed + 0.5 * acceleration * time ** 2;
      const distanceMaxSpeed =
        Math.max(0, currentTime - step.timeToMaxSpeed) * maxSpeed;
      const distance = distanceAcc + distanceMaxSpeed;

      const alpha = distance / step.distance;

      position.lerpVectors(step.start, step.end, Math.min(1, alpha));
      // Lerp lookAtPosition a little forward than current position
      lookAtPosition.lerpVectors(
        step.start,
        step.end,
        Math.min(1, alpha + 0.1),
      );

      if (alpha < 1) exit = true;
      else {
        indexPath++;

        currentTime -= step.time;
        step = segment.intervals[indexPath];
      }
    }

    return {
      position,
      lookAtPosition,
    };
  }

  /**
   * Animates the travel animation along the path over time.
   *
   * This function is called repeatedly during the animation loop to update the position
   * and rotation of the travel model (car or plane) based on the elapsed time. It also
   * updates the camera position and zoom to follow the model.
   *
   * @param delta The elapsed time since the last animation frame (in seconds)
   */
  update(delta: number): void {
    const zoom = this.map.getZoom();
    // Update model scale based on zoom
    if (this.model) this.updateModelScale(zoom);

    // Check if animation has expired
    if (!this.isAnimationExpired) {
      // Get current time and elapsed time
      const now = performance.now();
      this.timeElapsed = now - this.animationStartTime;

      const timeProgress = this.timeElapsed / this.duration;
      // this.timeElapsed /
      // ((this.animationConfig?.duration as number) /
      //   this.travelSegment.travelSegmentConfig.animationSpeed);
      // (this.path.start.screenTime + this.path.middle.screenTime + this.path.end.screenTime) * 1000

      setAnimationState(timeProgress);

      // Get the current map zoom level
      const zoom = this.map.getZoom();

      // Update model scale based on zoom
      this.updateModelScale(zoom);

      const preComputedData = this.getPrecomputedPosition(delta);
      if (!this.isAnimationExpired) {
        if (preComputedData) {
          const { position, lookAtPosition } = preComputedData;

          if (this.model) {
            this.model?.position.copy(position);

            if (lookAtPosition.distanceTo(position) > 0.0001) {
              // Create quaternions to represent the model's current and target rotations
              let q1 = new Quaternion().copy(
                (this.model as Object3D).quaternion,
              );
              const worldPos = (this.pathMesh as Mesh).localToWorld(
                lookAtPosition,
              );
              (this.model as Object3D).lookAt(worldPos);
              let q2 = new Quaternion().copy(
                (this.model as Object3D).quaternion,
              );

              // Calculate a normalized lerp value based on zoom and delta
              const normalizedLerp = 1 - Math.exp(-zoom * delta);

              // Slerp between the current and target quaternions for smooth rotation
              (this.model as Object3D).quaternion.slerpQuaternions(
                q1,
                q2,
                normalizedLerp,
              );
            }
          }

          const modelLngLatPos = unprojectFromWorld(position as Vector3);
          this.updateCamera(this.timeElapsed / 1000, modelLngLatPos);
          // Update the dynamic path line string
          this.updateLineString(this.dynamicPath);
        }
      } else {
        this.onAnimationEnded();
      }
    }
  }

  // Unused at the moment

  /**
   * Translates a value from an arbitrary range to the 0-1 interval.
   *
   * This function is  used for interpolation purposes, where a value
   * needs to be scaled to a specific range (often between 0 and 1) before
   * being used in calculations or animations.
   *
   * @param value The value to be translated
   * @param minRange The minimum value in the original range
   * @param maxRange The maximum value in the original range
   * @returns The translated value within the 0-1 interval
   */
  translateTo01(value: number, minRange: number, maxRange: number) {
    return (value - minRange) / (maxRange - minRange);
  }

  /**
   * Applies a custom easing function to a value ( for animation purposes).
   *
   * This function is  used to modify the behavior of a value over
   * time during an animation. The specific easing function (`customEaseInOut`)
   * is  defined elsewhere and controls the pacing of the animation
   * (e.g., slow start, fast middle, slow end).
   *
   * @param value The value to be eased
   * @returns The eased value
   */
  easeInOut(value: number): number {
    return customEaseInOut(value, this.scaleFactor);
  }

  /**
   * Applies a custom easing function for easing out ( for animation purposes).
   *
   * This function is similar to `easeInOut` and is specifically designed
   * for easing out animations (i.e., slowing down towards the end).
   *
   * @param value The value to be eased
   * @returns The eased value
   */
  easeOut(value: number): number {
    return customEaseOut(value, this.scaleFactor);
  }

  /**
   * Applies a custom easing function for easing in ( for animation purposes).
   *
   * This function is similar to `easeInOut` and is specifically designed
   * for easing in animations (i.e., slowing down at the beginning).
   *
   * @param value The value to be eased
   * @returns The eased value
   */
  easeIn(value: number): number {
    return customEaseIn(value, this.scaleFactor);
  }

  /**
   * Translates a value from the 0-1 interval back to an arbitrary range.
   *
   * This function is  the counterpart to `translateTo01`. It takes a value
   * within the 0-1 range and scales it back to the original range specified
   * by `minRange` and `maxRange`.
   *
   * @param value The value to be translated back
   * @param minRange The minimum value in the original range
   * @param maxRange The maximum value in the original range
   * @returns The translated value within the original range
   */
  translateBackToRange(value: number, minRange: number, maxRange: number) {
    return value * (maxRange - minRange) + minRange;
  }
}

export { LandTransportController };
