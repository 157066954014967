import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { signal } from '@preact/signals-core';
import { useSignalEffect } from '@preact/signals-react/runtime';
import './style.css';
import useIsAuth from '../useIsAuth';
import { showMakeYourOwn } from '../MakeYourOwnModal';
import { useAuth } from '~/managers/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import ActionsCreator from '~/redux/actions';
import { RootState } from '~/redux/reducers';

export const showDrawerMenu = signal(false);

const DrawerMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { signOut } = useAuth();

  useSignalEffect(() => {
    setIsVisible(showDrawerMenu.value);
  });

  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const profilePictureURL: string = useSelector(
    (state: RootState) => state.MapReducers.profileImgURL,
  );
  const selectedProfilePicture = '/icons/croppedLogoVizualTravel.svg';

  const dispatch = useDispatch();

  const isAuthenticated = useIsAuth();

  const menuItems = isAuthenticated
    ? [
        {
          label: 'Generate a new Trip',
          action: () => window.location.assign('/homepage'),
        },
        {
          label: 'Settings',
          action: () => window.location.assign('/settings'),
        },
        {
          label: 'Give us Feedback',
          action: () => {
            showDrawerMenu.value = false;
            setTimeout(() => {
              dispatch(ActionsCreator.setFeedbackPopupState(true));
            }, 100);
          },
        },
        {
          label: 'Log out',
          action: () => {
            signOut()
              .then(() => {
                const tokenKey = process.env
                  .REACT_APP_SUPABASE_TOKEN_KEY as string;
                localStorage.removeItem(tokenKey);
                dispatch(ActionsCreator.resetStateToInitial());
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
      ]
    : [
        {
          label: 'Generate a new Trip',
          action: () => {
            showDrawerMenu.value = false;
            setTimeout(() => {
              showMakeYourOwn.value = 'generate';
            }, 100);
          },
        },
        {
          label: 'Give us Feedback',
          action: () => {
            showDrawerMenu.value = false;
            setTimeout(() => {
              dispatch(ActionsCreator.setFeedbackPopupState(true));
            }, 100);
          },
        },
      ];

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          transition={{
            ease: 'easeInOut',
            duration: 0.25,
            type: 'spring',
            bounce: 0.15,
          }}
          initial={{ x: -window.innerWidth }}
          animate={{ x: 0 }}
          exit={{ x: -window.innerWidth }}
          className="drawer-menu-container"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                showDrawerMenu.value = false;
              }}
              style={{ pointerEvents: 'all', width: '20%' }}
            >
              <img src="./goBack.png" width={50} height={35} />
            </div>
            <img src="./logoVizualTravel.svg" width={124} height={26} />
            <div style={{ width: '20%' }} />
          </div>

          <div style={{ paddingTop: 52 }}>
            {isAuthenticated ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ borderRadius: 56 }}
                  height={56}
                  width={56}
                  src={
                    !profilePictureURL
                      ? selectedProfilePicture
                      : profilePictureURL
                  }
                  alt="profilePicture"
                />
                <div style={{ paddingLeft: 16 }}>
                  <span className="drawer-menu-user-name">
                    Logged in as{' '}
                    <span
                      style={{ fontWeight: '700', textOverflow: 'ellipsis' }}
                    >
                      {userName}
                    </span>
                  </span>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  className="drawer-menu-button"
                  onClick={() => {
                    window.location.assign('/signup');
                  }}
                >
                  Sign Up
                </div>
                <div style={{ paddingLeft: 16 }}>
                  <span className="drawer-menu-login-text">{`Already member?`}</span>
                  <a className="drawer-menu-login-text" href="/login">
                    {' '}
                    Log in
                  </a>
                </div>
              </div>
            )}
            {menuItems.map((i, idx) => {
              return (
                <div
                  key={i.label + idx}
                  className={'drawer-menu-item'}
                  onClick={() => i.action?.()}
                >
                  {i.label}
                </div>
              );
            })}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DrawerMenu;
