import React, { useState, useEffect, useRef, memo } from 'react';
import {
  AnimatePresence,
  motion,
  PanInfo,
  useAnimation,
  useMotionValue,
} from 'framer-motion';
import './style.css';
import { ChevronRight } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { isCalendarAnimating } from '../DesktopFooter/CalendarLine';

import { isOverlayVisible, TravelPoint } from '../StatsOverlay';
import { signal } from '@preact/signals-core';
import {
  mobile4thImagePostionSignal,
  PauseAnimation,
  PlayAnimation,
  animationStateSignal,
  showPlayPauseButton,
  animationIsPlaying,
} from '../common';
import PlayPauseButton from '~/components/PlayPauseButton';
import OverviewDataComponent, { OverviewData } from './OverviewDataComponent';
import { mapSignal, restartDaySignal } from '~/map/ViewTravel';
import DaysHeader, {
  selectedTravelDaySignal,
  VizualizeButtonData,
  vizualizeButtonSignal,
} from './DaysHeader';
import PointsHerader from './PointsHeader';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { PointLike } from 'maplibre-gl';
import MobileVizualizeButton from './MobileVizualizeButton';
import PlayHeader from './PlayHeader';
import CollapsedCards from './CollapsedCards';
import PointsList from './PointsList';
import HeaderDescription from './BottomSheetHeaderDescription';
import { useIsMobile } from '../counter/hooks/useMobile';
import { fetchAndStoreUserID } from '~/utility/utils';
import MakeYourOwnModal, { showMakeYourOwn } from '../MakeYourOwnModal';
import useIsAuth from '../useIsAuth';

const bottomsheetCollapseSignal = signal(0);
export const bottomSheetOverviewData = signal<OverviewData | null>(null);
export const mapOffset = signal<PointLike>([0, 0]);
export const resetTrip = signal(false);

const BOTTOM_SHEET_OPTIONS = {
  visible: { y: 0 },
  half: { y: window.innerHeight / 2 },
  hidden: { y: window.innerHeight - 224 },
};
const BOTTOM_SHEET_STATES: (keyof typeof BOTTOM_SHEET_OPTIONS)[] = [
  'hidden',
  'half',
  'visible',
];

const Wrapper = memo(
  ({
    draggable,
    setOpened,
    isAnimationIdle,
    travelData,
    setStep,
    startTrip,
  }: React.PropsWithChildren<{
    draggable: boolean;
    setOpened: any;
    travelData: TravelPoint[];
    isAnimationIdle: boolean;
    setStep: (v: string) => void;
    startTrip?: (v?: number) => void;
  }>) => {
    const bottomSheetRef = useRef<HTMLDivElement>();
    const [overviewData, setOverviewData] = useState<OverviewData | null>(null);
    const controls = useAnimation();
    const [isOverlay, setIsOverlay] = useState(isOverlayVisible.peek());

    const isAuthenticated = useIsAuth();
    const isMobile = useIsMobile();

    useSignalEffect(() => {
      setIsOverlay(isOverlayVisible.value);
    });

    const [currentVariant, setCurrentVariant] = useState<number>(0);
    const prev = useRef(0);
    const animatedY = useMotionValue(0);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
      controls.start(BOTTOM_SHEET_OPTIONS[BOTTOM_SHEET_STATES[currentVariant]]);
      if (currentVariant === 0) {
        onClose();
      } else if (currentVariant === 1 && prev.current !== 2) {
        onOpen();
      }
    }, [currentVariant]);

    useSignalEffect(() => {
      setIsPlaying(animationIsPlaying.value);
      if (!!animationIsPlaying.value) {
        setCurrentVariant(0);
      }
    });

    const contentRef = useRef<HTMLDivElement>(null);
    const navbarref = useRef<HTMLDivElement>(null);

    const playPauseState = useSelector(
      (state: any) => state.AnimationReducers.playPauseState,
    );

    function onClose() {
      mapOffset.value = [0, 0];
      if (!isAnimationIdle) {
        mapSignal.value?.panTo(mapSignal.value?.getCenter(), {
          offset: [0, window.innerHeight * 0.2],
        });
      }
      setOpened(false);

      bottomSheetOverviewData.value = null;
      if (showPlayPauseButton.value && !isPlaying) {
        isOverlayVisible.value = false;
        PlayAnimation();
        setTimeout(() => {
          PlayAnimation();
        }, 0);
      }
    }

    function onOpen() {
      mapOffset.value = [0, -window.innerHeight * 0.2];
      if (!isAnimationIdle) {
        mapSignal.value?.panTo(mapSignal.value?.getCenter(), {
          offset: mapOffset.value,
        });
      }
      if (showPlayPauseButton.value && isPlaying) {
        PauseAnimation();
      }

      setOpened(true);
    }

    const onDragEnd = (_: PointerEvent, info: PanInfo) => {
      const y = bottomSheetRef.current?.getBoundingClientRect().top || 0;

      const velocity =
        Math.abs(info.velocity.y) > Math.abs(info.velocity.x)
          ? info.velocity.y
          : 0;

      let dragPosition = 0;
      if (y >= window.innerHeight * 0.75) {
        dragPosition = 0;
      } else if (
        y < window.innerHeight * 0.75 &&
        y > window.innerHeight * 0.35
      ) {
        dragPosition = 1;
      } else {
        dragPosition = 2;
      }

      let velocityPosition =
        velocity > 100
          ? Math.max(0, currentVariant - 1)
          : velocity < -100
          ? Math.min(2, currentVariant + 1)
          : dragPosition;

      if (velocity === 0) {
        velocityPosition = dragPosition;
      }
      controls.start(
        BOTTOM_SHEET_OPTIONS[BOTTOM_SHEET_STATES[velocityPosition]],
      );
      prev.current = currentVariant;
      setCurrentVariant(velocityPosition);
    };

    useSignalEffect(() => {
      if (showPlayPauseButton.value) {
        BOTTOM_SHEET_OPTIONS.hidden = { y: window.innerHeight };
      } else {
        BOTTOM_SHEET_OPTIONS.hidden = { y: window.innerHeight - 224 };
      }
      setCurrentVariant(0);
      controls.start('hidden');
    });

    useEffect(() => {
      const unsub = bottomSheetOverviewData.subscribe((data) => {
        setOverviewData(data);
        prev.current = currentVariant;
        if (data) {
          !animationIsPlaying.value &&
            mapSignal.value?.flyTo({
              center: bottomSheetOverviewData.value?.location as any,
              offset: [0, -window.innerHeight * 0.2],
              zoom: 14,
            });

          setCurrentVariant((p) => (p === 0 ? 1 : p));
        } else {
          setCurrentVariant(0);
        }
      });
      return () => unsub();
    }, []);

    useEffect(() => {
      const unsub = bottomsheetCollapseSignal.subscribe(() => {
        bottomSheetOverviewData.value = null;
        setCurrentVariant(0);
      });

      return () => {
        unsub();
      };
    }, []);

    useEffect(() => {
      const updateTranslateValue = () => {
        if (contentRef.current && navbarref.current) {
          mobile4thImagePostionSignal.value =
            contentRef.current.clientHeight +
            navbarref.current.clientHeight +
            50 +
            'px';
        }
      };
      setTimeout(updateTranslateValue, 1000);
    }, []);

    const handleVisibilityChange = (e: any) => {
      if (document.hidden && showPlayPauseButton.value) {
        resetTrip.value = true;
        restartDaySignal.value = true;
        setCurrentVariant(0);
        isOverlayVisible.value = true;
      }
    };

    useEffect(() => {
      window.addEventListener('visibilitychange', handleVisibilityChange);
      return () =>
        window.removeEventListener('visibilitychange', handleVisibilityChange);
    }, []);

    return (
      <>
        <MakeYourOwnModal />
        <AnimatePresence>
          <motion.div
            //@ts-ignore
            ref={bottomSheetRef}
            dragDirectionLock
            drag={overviewData ? 'y' : false}
            onDragEnd={onDragEnd}
            initial={'hidden'}
            animate={controls}
            transition={{
              type: 'spring',
              damping: 40,
              stiffness: 400,
            }}
            onTouchStart={() => {
              if (!isOverlayVisible.peek() && !animationIsPlaying.peek())
                isOverlayVisible.value = true;
            }}
            variants={BOTTOM_SHEET_OPTIONS}
            dragConstraints={{ top: 0 }}
            dragElastic={0.2}
            style={{
              y: animatedY,
              position: 'fixed',
              opacity: isOverlay ? 1 : 0.4,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 999,
              backgroundColor: 'white',
              width: isMobile ? '100%' : '70%',
              margin: 'auto',
              height: window.innerHeight,
              border: '1px solid #E0E0E0',
              boxShadow:
                '0px 2px 5px rgba(0, 0, 0, 0.06), 0px 2px 13px rgba(0, 0, 0, 0.12)',
              borderRadius: '15px 15px 0px 0px',
              paddingRight: '5px',
              paddingLeft: '5px',
              fontFamily: 'Manrope',
            }}
          >
            {showPlayPauseButton.value && (
              <PlayPauseButton
                playPauseState={playPauseState}
                bottom={window.innerHeight + 20}
                left={'10px'}
                type={'mobile'}
                animatedY={animatedY}
              />
            )}
            {/* {showPlayPauseButton.value && (
            <div
              className="overviewContainer"
              onClick={() => {
                resetTrip.value = true;
                restartDaySignal.value = true;
                setCurrentVariant(0);
              }}
            >
              <img
                src={`./icons/overviewRoutOrange.png`}
                alt={'overviewRoute'}
                height={24}
                width={24}
              />
              <span className="overviewText">Overview</span>
            </div>
          )} */}
            {!showPlayPauseButton.value && (
              <div
                className="makeYourOwnButton"
                onClick={async () => {
                  if (isAuthenticated) {
                    window.location.assign('/homepage');
                  } else {
                    showMakeYourOwn.value = 'make';
                  }
                }}
              >
                <span className="makeYourOwnText">Make your Own</span>
              </div>
            )}
            <motion.div
              className="DragHandleEdge"
              style={{
                touchAction: 'none',
                paddingTop: 5,
                paddingBottom: 5,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {!isAnimationIdle || overviewData ? (
                <motion.div
                  className="DragHandle"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.15 }}
                />
              ) : null}
            </motion.div>
            {!overviewData && <HeaderDescription travelData={travelData} />}
            <motion.div
              style={{
                height: isAnimationIdle ? 75 : overviewData ? 0 : 40,
                overflow: 'hidden',
              }}
              ref={navbarref}
            >
              <motion.div
                animate={isAnimationIdle ? { y: 0 } : { y: -40 }}
                initial={{ y: 0 }}
                transition={{ type: 'tween' }}
              >
                <div
                  style={{
                    height: isAnimationIdle ? 80 : 40,
                    overflow: 'hidden',
                  }}
                >
                  <AnimatePresence>
                    <motion.div
                      animate={currentVariant > 0 ? { y: -80 } : { y: 0 }}
                      transition={{ duration: 0.3 }}
                      initial={{ y: 0 }}
                    >
                      <DaysHeader travelData={travelData} />
                      <PointsHerader travelData={travelData} />
                    </motion.div>
                  </AnimatePresence>
                </div>
                {/* <PlayHeader travelData={travelData} /> */}
              </motion.div>
            </motion.div>
            <div
              ref={contentRef}
              className="p-1"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: draggable ? 'hidden' : 'visible',
              }}
            >
              {!overviewData ? (
                isAnimationIdle ? (
                  <MobileVizualizeButton
                    setStep={setStep}
                    startTrip={(v) => startTrip?.(v)}
                  />
                ) : null
              ) : (
                // currentVariant > 0 ? (
                //   <PointsList
                //     travelData={travelData}
                //     handleChangeTripIndex={startTrip}
                //   />
                // ) : (
                //   <CollapsedCards
                //     handleChangeTripIndex={startTrip}
                //     travelData={travelData as any}
                //   />
                // )
                <OverviewDataComponent />
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </>
    );
  },
);

function Collapsed({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

Collapsed.displayName = 'Collapsed';

function Header({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
}

Header.displayName = 'Header';

function Content({ children }: { children: React.ReactNode }) {
  return (
    <div onPointerDownCapture={(e) => e.stopPropagation()}>{children}</div>
  );
}

Content.displayName = 'Content';

const TripTo = ({
  to,
  startDate,
  endDate,
}: {
  to: string;
  startDate: string;
  endDate: string;
}) => {
  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  return (
    <>
      <div className="TripTOMobileBottomSheet flex-1">
        <div className="d-flex">
          <span>Trip to </span>&nbsp;<b> {to} </b>
        </div>
        <div className="d-flex">
          {userName && (
            <>
              <span>Made by&nbsp;</span>
              <b>{userName}</b>
            </>
          )}
        </div>
      </div>
    </>
  );
};

TripTo.displayName = 'TripTo';

export const collapseBottomSheet = () => {
  bottomsheetCollapseSignal.value = bottomsheetCollapseSignal.peek() + 1;
};

export const openBottomSheet = (data: OverviewData) => {
  bottomSheetOverviewData.value = data;
};

export default {
  Wrapper,
  Collapsed,
  Header,
  Content,
  TripTo,
};
