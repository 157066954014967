import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import moment, { Moment } from 'moment';
import 'swiper/css';
import { signal } from '@preact/signals-core';
import { Arrival, TravelPoint } from '../StatsOverlay';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { Swiper as SwiperType } from 'swiper/types';
import { selectedTravelDaySignal } from './DaysHeader';
import { bottomSheetOverviewData } from './BottomSheet';
import { OverviewData } from './OverviewDataComponent';
import { durationString } from '../common';
import { getIconByCategory } from './helpers';

export const pointsSignal = signal<OverviewData[]>([]);

const PointsHerader = ({ travelData }: { travelData: TravelPoint[] }) => {
  const [selectedPoint, setSelectedPoint] = useState<OverviewData | null>(null);
  const [dayTravel, setDayTravel] = useState<
    (TravelPoint & { travelIndex?: number })[]
  >([]);
  const swiperRef = useRef<SwiperType>();

  const pointsOverview = useMemo(
    () => [
      ...dayTravel.map((i, index, arr) => ({
        travelIndex: i.travelIndex,
        index: index,
        location: i.departure.location?.coordinates as any,
        name: i.departure.location?.label || '',
        street: i.departure.location.street,
        id: i.departure.location.placeId,
        icon: getIconByCategory(i.departure.category),
        description:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda velit voluptatibus accusantium sunt suscipit eos commodi ullam omnis adipisci.',
        images: i.departure.images || [],
        duration:
          index > 0
            ? durationString(
                moment(arr[index - 1].arrival.dateTime),
                moment(arr[index - 1].departure.dateTime),
                true,
              )
            : undefined,
        staypointDuration:
          index === 0
            ? 'START'
            : index > 0 && index < arr.length
            ? durationString(
                moment(i.departure.dateTime),
                moment(arr[index - 1].arrival.dateTime),
                true,
              )
            : undefined,
        price: 10,
        point: i.departure,
        type: i.selectedTransport,
      })),
      ...(dayTravel.length > 0
        ? [
            {
              travelIndex: dayTravel[dayTravel.length - 1].travelIndex,
              index: dayTravel.length,
              location: dayTravel[dayTravel.length - 1].arrival.location
                ?.coordinates as any,
              name:
                dayTravel[dayTravel.length - 1].arrival.location?.label || '',
              street: dayTravel[dayTravel.length - 1].arrival.location.street,
              id: dayTravel[dayTravel.length - 1].arrival.location.placeId,
              icon: getIconByCategory(
                dayTravel[dayTravel.length - 1].arrival.category,
              ),
              description:
                'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda velit voluptatibus accusantium sunt suscipit eos commodi ullam omnis adipisci.',
              images: dayTravel[dayTravel.length - 1].arrival.images || [],
              price: 10,
              point: dayTravel[dayTravel.length - 1].arrival,
              type: dayTravel[dayTravel.length - 1].selectedTransport,
              staypointDuration: 'END',
            },
          ]
        : []),
    ],
    [dayTravel],
  );

  useEffect(() => {
    pointsSignal.value = pointsOverview;
  }, [pointsOverview]);

  useSignalEffect(() => {
    const filteredArray = travelData
      .map((i, idx) => ({ ...i, travelIndex: idx }))
      .filter((i) => {
        return (
          moment(String(i.departure.dateTime))
            .tz(i.departure.timezone)
            .format('DD-MM-YYYY') ===
          selectedTravelDaySignal.value?.format('DD-MM-YYYY')
        );
      });
    setDayTravel(
      selectedTravelDaySignal.value
        ? filteredArray
        : travelData.map((i, idx) => ({ ...i, travelIndex: idx })),
    );
  });

  useSignalEffect(() => {
    setSelectedPoint(bottomSheetOverviewData.value);
    bottomSheetOverviewData.value?.index &&
      swiperRef.current?.slideTo(bottomSheetOverviewData.value?.index);
  });

  return (
    <Swiper
      //@ts-ignore
      onSwiper={(ref) => (swiperRef.current = ref)}
      slidesPerView="auto"
      spaceBetween={10}
      slidesPerGroup={1}
      style={{ paddingTop: 5, paddingBottom: 10 }}
      centeredSlides
      longSwipesRatio={1}
    >
      {pointsOverview.map((i, idx) => {
        const selected = selectedPoint?.index === i.index;
        return (
          <SwiperSlide style={{ width: 51 }} key={idx.toString()}>
            <div
              onClick={() => {
                bottomSheetOverviewData.value = i;
                swiperRef.current?.slideTo(idx);
              }}
              style={{
                backgroundColor: selected ? '#FE7138' : '#FF91471A',

                height: 60,
                width: 51,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                borderRadius: 12,
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {'Point'}
              </div>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {idx + 1}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default PointsHerader;
