import { Box, Link, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { OverviewData } from '.';
import { showStoriesSignal } from '../../StoriesOverlay';

const SLIDE_SIZE = 200;

// const generateDirectionLink = (i: OverviewData) => {
//   return `https://www.google.com/maps/search/${i.name.replaceAll(
//     ' ',
//     '+',
//   )}+${i.street?.replaceAll(' ', '+')}/`;
// };

const OverviewContainer = ({ data }: { data: OverviewData }) => {
  const [imageError, setImageError] = useState(false);
  return (
    <div
      style={{
        scrollbarWidth: 'none',
        paddingBottom: '30px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: window.innerHeight * 0.8 - 80,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.15 }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 8,
          marginBottom: 24,
          maxWidth: '90%',

          justifyContent: 'center',
        }}
      >
        <img
          src={data.icon}
          height={48}
          style={{ marginRight: 10 }}
          loading="lazy"
        />
        <Typography
          fontSize={24}
          fontFamily={'Poppins'}
          fontWeight={'700'}
          style={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {data.name}
        </Typography>
      </motion.div>
      <div
        style={{
          width: '95%',
          display: 'flex',
          flexDirection: 'row',
          // marginLeft: 10,
          // marginRight: 10,
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.25 }}
        >
          <div
            style={{
              width: SLIDE_SIZE,
              height: SLIDE_SIZE,
              borderRadius: 32,
              overflow: 'hidden',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'lightgray',
              boxShadow:
                '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933,0px 1.5px 1.5px 0px #FFFFFF40 inset',
            }}
            onClick={() => {
              if (data.images.length > 0) showStoriesSignal.value = data.images;
            }}
          >
            {data.images.length > 0 && !imageError ? (
              <img
                width={SLIDE_SIZE}
                height={SLIDE_SIZE}
                src={data.images[0]}
                alt={String('img')}
                loading="lazy"
                onError={() => setImageError(true)}
              />
            ) : (
              <img src={data.icon} height={SLIDE_SIZE * 0.5} loading="lazy" />
            )}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.35 }}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            width: '95%',
            paddingTop: 24,
            paddingBottom: 24,
          }}
        >
          <Typography
            fontSize={22}
            fontWeight={700}
            fontFamily={'Poppins'}
            // pt={'16px'}
            textAlign={'center'}
            color={'#FF8447'}
          >
            {moment(String(data.point?.dateTime || ''))
              .tz(data.point?.timezone || '')
              .format('hh:mm A')}
          </Typography>
          <Typography
            fontSize={22}
            fontWeight={600}
            fontFamily={'Poppins'}
            // pt={'16px'}
            textAlign={'center'}
          >
            {data.staypointDuration}
          </Typography>
          {/* <Typography
            fontSize={22}
            fontWeight={600}
            fontFamily={'Poppins'}
            textAlign={'center'}
          >
            {Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'USD',
            }).format(+(data.price || 0))}
          </Typography> */}
        </motion.div>
      </div>
      {/* <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.35 }}
      >
        <Typography
          fontSize={14}
          fontWeight={500}
          fontFamily={'Poppins'}
          px={6}
          pt={'16px'}
          textAlign={'center'}
        >
          {data.description}
        </Typography>
      </motion.div> */}
      {/* <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.45 }}
        style={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '90%',
        }}
      >
        <LinkButton
          icon={'./icons/directionsIcon.png'}
          text={'Direction'}
          href={generateDirectionLink(data)}
        />
        <LinkButton
          icon={'./icons/websiteIcon.png'}
          text={'Website'}
          // href={generateDirectionLink(i)}
        />
        <LinkButton
          icon={'./icons/shareIcon.png'}
          text={'Share'}
          // href={generateDirectionLink(i)}
        />
        <LinkButton
          icon={'./icons/saveImage.png'}
          text={'Save'}
          // href={generateDirectionLink(i)}
        />
      </motion.div> */}
    </div>
  );
};

const LinkButton = ({
  icon,
  text,
  href,
}: {
  icon: string;
  text: string;
  href?: string;
}) => {
  return (
    <Link href={href} underline="none" target="_blank" rel="noreferrer">
      <Box
        bgcolor="#D5E2FF"
        borderRadius={'18px'}
        border={'1px solid #C0D5FF'}
        display={'flex'}
        flexDirection={'column'}
        color={'#C0D5FF'}
        alignItems={'center'}
        justifyContent={'center'}
        height={64}
        width={64}
      >
        <img
          width={24}
          height={24}
          src={icon}
          alt={'directionsIcon'}
          loading="lazy"
        />
        <Typography
          fontSize={11}
          fontWeight={500}
          color={'#3559E9'}
          marginTop={'10px'}
          fontFamily={'Poppins'}
          textTransform={'capitalize'}
          textAlign={'center'}
        >
          {text}
        </Typography>
      </Box>
    </Link>
  );
};

export default OverviewContainer;
