import {
  AnimationTravelData,
  Model,
  ModelKeys,
  ModelDict,
  PublishableTravelDataWithDecodedPath,
} from '~/utility/models';
import { Map as MaplibreMap } from 'maplibre-gl';
import { TextAnimation } from '../TextAnimation';
import ResourceLoader from '../ResourceLoader';
import { CarAnimationController } from '../CarAnimationController';
import { PlaneAnimationController } from '../PlaneAnimationController';
import { projectToWorld } from '~/CustomThreeJsWrapper/utility/utils';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import { TravelMode } from '../utility/enums/TravelMode';
import { State } from './models';
import { AnimateCameraToOrigin } from './state/AnimateCameraToOrigin';
import { AnimateOriginMarker } from './state/AnimateOriginMarker';
import { AnimateTravelSegment } from './state/AnimateTravelSegment';
import { AnimateDestinationMarker } from './state/AnimateDestinationMarker';
import { MoveToSegment } from './state/MoveToNextSegment';
import { MarkerModels } from '../utility/enums/MarkerModels';
import { AnimationController } from '../AnimationController';
import { MultiTransportAnimationController } from '../MultiTransportAnimationController';
import { FerryAnimationController } from '../FerryAnimationController';
import { Position } from '@turf/turf';
import { AnimateStraightLine } from './state/AnimateStraightLine';
import { WalkAnimationController } from '../Walk/WalkAnimationController';
import { OriginDelayState } from './state/OriginDelayState';
import { DestinationDelayState } from './state/DestinationDelayState';
import { clickedIndexSignal } from '~/components/ViewTravel/common';
import { StraightLineController } from '../StraightLineController';
import { ClearPreviousTravelSegment } from './state/ClearPreviousTravelSegment';

/**
 * A type alias for a Map that stores resource keys (strings) and their corresponding
 * Three.js Object3D instances or null values indicating resources haven't been loaded yet.
 */
type ResourceTracker = Map<ModelKeys[], ModelDict[] | null>;

/**
 * Constant string representing the key used to identify the marker model enum.
 * This is  used for referencing a specific pre-defined marker model.
 */
export const MARKER_ENUM_KEY: ModelKeys = MarkerModels.MapPointer;

/**
 * This class animates travel paths along a map. It takes travel data as input,
 * fetches required resources (3D models), and animates vehicles or planes
 * along the provided route.
 */
export class TravelAnimation {
  /**
   * An array containing animation data for each travel segment within the travel path.
   * This data is used to define the animation behavior (e.g., path, speed, transitions)
   * for each segment.
   */
  animationTravelData: AnimationTravelData[] = [];

  /**
   * An array of animation controllers, where each controller corresponds to a specific
   * travel segment in the `animationTravelData` array. These controllers are responsible
   * for managing the animation of their respective segments, including playing, pausing,
   * rewinding, and handling other animation-related tasks based on your project's needs.
   * The specific type of controller used can vary depending on the travel segment type
   * (e.g., `CarAnimationController` for car travel segments, `PlaneAnimationController`
   * for airplane travel segments). The `null` value is used to indicate that on close
   * these controllers are destroyed and set to null
   */
  animationControllers!: (
    | AnimationController
    | MultiTransportAnimationController
    | undefined
  )[];

  straightLineControllers!: StraightLineController[];

  /**
   * A reference to a custom Three.js wrapper class that you've created or are using in your
   * project. This wrapper class  provides a simplified interface or additional functionality
   * on top of the core Three.js library, making it easier to work with Three.js objects and
   * scenes within your travel animation application.
   */
  tb: CustomThreeJSWrapper;

  /**
   * A reference to the Maplibre map instance that is being used to display the travel path
   * and potentially other geographical elements within your application. This allows you to
   * interact with the map and potentially synchronize the travel animation with the map view.
   */
  map: MaplibreMap;

  /**
   * A callback function that is invoked whenever the loading state of resources used in
   * the travel animation changes. This function typically receives a boolean argument
   * `newLoadingState` that indicates whether the resources are still loading (`true`) or
   * have finished loading (`false`). You can use this callback to update the user interface
   * or perform other actions based on the loading state.
   *
   * @param newLoadingState - A boolean flag indicating the new loading state:
   *   - `true`: Resources are still loading.
   *   - `false`: Resources have finished loading.
   */
  onResourcesLoadingStateUpdate: (newLoadingState: boolean) => void;

  /**
   * A boolean flag that indicates whether the travel path being displayed is a published
   * path or one that is intended for viewing purposes only.
   */
  isPublishedTravelPath: boolean;

  /**
   * A callback function that is called when the travel animation reaches its end. This
   * allows you to perform necessary actions after the animation finishes, such as
   * displaying a completion message, transitioning to a different view, or restarting
   * the animation.
   */
  callbackOnTravelAnimationEnd: () => void;

  /**
   * A constant that defines the default total duration of the entire travel animation,
   * specified in seconds. This value can be used as a baseline for the animation
   * timeline and can be adjusted based on specific needs or user preferences.
   */
  DEFAULT_ANIMATION_TOTAL_TIME = 15;

  /**
   * A boolean flag that enables developer mode. When enabled, this flag might activate
   * additional debugging features, logging, or visual aids that can be helpful during
   * development.
   */
  devMode = false;

  /**
   * Initial map bearing (rotation) in degrees. Defaults to 0 (north).
   */
  mapBearing = 0;

  /**
   * Initial map pitch (vertical tilt) in degrees. Defaults to 20.
   */
  mapPitch = 20;

  /**
   * An object containing travel animation states, keyed by state names.
   */
  states: { [key: string]: State };

  /**
   * The current active travel animation state.
   */
  currentState: State;

  /**
   * The default travel animation state.
   */
  firstState: State;

  /**
   * The index of the current travel segment being animated (starts from 0).
   */
  currentSegmentIndex = 0;

  /**
   * Determines whether the origin marker will appear or not.
   *
   * @default true
   */
  showMarker: boolean = true;

  showStraightLine: boolean = false;
  straightLineOrigin!: Position;
  straightLineDestination!: Position;
  isNextStateDestination: boolean = false;

  showNoTransportationParts: boolean = false;

  noTransportOrigin!: Position;

  noTransportDestination!: Position;

  /**
   * Constructor for the TravelAnimation class
   *
   * @param map A reference to the Maplibre map instance
   * @param tb A reference to the Three.js wrapper class
   * @param onResourcesLoadingStateUpdate Callback function to update the loading state of resources
   * @param isViewTravel Flag to indicate whether the travel path is published or for viewing
   * @param callbackOnTravelAnimationEnd Callback function to be called when the travel animation ends
   */
  constructor(
    map: MaplibreMap,
    tb: CustomThreeJSWrapper,
    onResourcesLoadingStateUpdate: (newLoadingState: boolean) => void,
    isViewTravel: boolean,
    callbackOnTravelAnimationEnd: () => void,
  ) {
    this.map = map;
    this.tb = tb;
    this.animationControllers = [];
    this.onResourcesLoadingStateUpdate = onResourcesLoadingStateUpdate;
    this.isPublishedTravelPath = isViewTravel;
    this.callbackOnTravelAnimationEnd = callbackOnTravelAnimationEnd;

    // Initialize states as an object with key-value pairs
    // Key is the state name, value is an instance of the corresponding State class
    this.states = {
      animateCameraToOrigin: new AnimateCameraToOrigin(this, this.map),
      animateStraightLine: new AnimateStraightLine(this, this.map, this.tb),
      animateOriginMarker: new AnimateOriginMarker(this),
      animateTravelSegment: new AnimateTravelSegment(this),
      animateDestinationMarker: new AnimateDestinationMarker(this),
      clearPreviousTravelSegment: new ClearPreviousTravelSegment(this),
      moveToSegment: new MoveToSegment(this),
      originDelayState: new OriginDelayState(this),
      destinationDelayState: new DestinationDelayState(this),
    };

    this.straightLineControllers = [];
    // Set the initial state to animateCameraToOrigin
    this.firstState = this.states.animateCameraToOrigin;
    this.currentState = this.firstState;
    // Set the initial travel segment index
    this.currentSegmentIndex = 0;
  }

  /**
   * Transitions the travel animation to a new state
   *
   * @param newState The new state to switch to
   */
  setState(newState: State) {
    if (this.currentState) this.currentState.onExit();
    this.currentState = newState;
    this.currentState.onEnter();
  }

  /**
   * Sets up the animation by fetching resources (3D models) and processing travel data.
   * This function performs the following steps:
   * 1. Fetches required 3D models using the `setupResources` function.
   * 2. Processes the provided travel data to prepare it for animation using `setupAnimationTravelData`.
   * 3. Updates the resource loading state using the `onResourcesLoadingStateUpdate` callback.
   * 4. Initializes animation controllers for each travel segment if necessary.
   *   - Creates `PlaneAnimationController` instances for plane travel segments.
   *   - Creates `CarAnimationController` instances for car travel segments.
   * 5. Calls the `setup` function on each animation controller to configure it with travel data.
   *
   * @param travelData An array of travel data objects with decoded paths
   * @returns A promise that resolves when the setup is complete
   */
  async setup(
    travelData: PublishableTravelDataWithDecodedPath[],
  ): Promise<void> {
    const resourceTracker = await this.setupResources(travelData);

    this.animationTravelData = await this.setupAnimationTravelData(
      travelData,
      resourceTracker,
    );

    this.onResourcesLoadingStateUpdate(false);
    if (this.animationTravelData.length > 0) {
      if (this.animationControllers.length === 0) {
        this.initializeAnimationClasses(
          this.animationTravelData,
          this.animationControllers as AnimationController[],
        );
      }
    }
  }

  initializeAnimationClasses(
    animationTravelData: AnimationTravelData[],
    animationControllers: (
      | AnimationController
      | MultiTransportAnimationController
    )[],
  ) {
    for (let i = 0; i < animationTravelData.length; i += 1) {
      if (animationTravelData[i].selectedTransport === TravelMode.Plane) {
        animationControllers[i] = new PlaneAnimationController(
          this.map,
          i,
          this.tb,
        );
      } else if (animationTravelData[i].selectedTransport === TravelMode.Car) {
        if (animationTravelData[i].decodedPath.data.length > 0) {
          animationControllers[i] = new MultiTransportAnimationController(
            this.map,
            i,
            this.tb,
          );
        } else if (animationTravelData[i].decodedPath.path.length > 0) {
          animationControllers[i] = new CarAnimationController(
            this.map,
            i,
            this.tb,
          );
        }
      } else if (
        animationTravelData[i].selectedTransport === TravelMode.Ferry
      ) {
        if (animationTravelData[i].decodedPath.data.length > 1) {
          // Handle Multi-Ferry Route
          animationControllers[i] = new MultiTransportAnimationController(
            this.map,
            i,
            this.tb,
          );
        } else {
          // Handle Single-Ferry Route
          animationControllers[i] = new FerryAnimationController(
            this.map,
            i,
            this.tb,
          );
        }
      } else if (
        animationTravelData[i].selectedTransport === TravelMode.Transit
      ) {
        animationControllers[i] = new MultiTransportAnimationController(
          this.map,
          i,
          this.tb,
        );
      } else if (animationTravelData[i].selectedTransport === TravelMode.Walk) {
        if (animationTravelData[i].decodedPath.data.length > 0) {
          animationControllers[i] = new MultiTransportAnimationController(
            this.map,
            i,
            this.tb,
          );
        } else if (animationTravelData[i].decodedPath.path.length > 0) {
          animationControllers[i] = new WalkAnimationController(
            this.map,
            i,
            this.tb,
          );
        }
      }

      animationControllers[i]?.setup(animationTravelData[i], i, true);
    }
  }

  getCurrentSegment() {
    return this.animationTravelData[this.currentSegmentIndex];
  }

  getCurrentController() {
    return this.animationControllers[this.currentSegmentIndex];
  }

  setPathLayerOnMapStyleChange() {
    if (this.animationControllers.length > 0) {
      for (let i = 0; i < this.animationControllers.length; i += 1) {
        if (this.animationControllers[i]) {
          (
            this.animationControllers[i] as AnimationController
          ).setLineLayerAndSources();
        }
      }
    }
  }

  /**
   * Starts the travel animation along the provided travel segments.
   *
   * This function iterates through the travel data and triggers segment-by-segment
   * animation using the corresponding animation controller (PlaneAnimationController or CarAnimationController).
   * It handles different scenarios based on the number of segments, published vs. viewing mode,
   * and animation completion callbacks.
   */
  async startAnimation() {
    this.setState(this.firstState);
  }

  /**
   * Disposes of resources held by the animation controllers.
   * This function is typically called when the animation is no longer needed.
   */
  dispose(): void {
    if (this.animationControllers.length > 0) {
      for (const controller of this.animationControllers) {
        controller?.cleanup();
      }
    }
    if (this.straightLineControllers.length > 0) {
      for (const controller of this.straightLineControllers) {
        controller?.cleanup();
      }
    }
  }

  /**
   * Updates the animation for each active travel segment by calling the respective controller's `animate` function.
   * This function should be called within the animation loop to continuously update the positions of vehicles or planes along the rout
   */
  update(): void {
    if (
      (this.currentState &&
        this.currentState.isPaused !== undefined &&
        !this.currentState.isPaused) ||
      (this.currentState && this.currentState?.isPaused === undefined)
    ) {
      if (this.currentState.onUpdate) this.currentState.onUpdate();
    }
  }

  /**
   * Clears animation data and resources associated with the travel animation.
   * This function is typically called when the animation needs to be reset.
   */
  clean() {
    this.dispose();

    // Reset PostFerry Line Source
    if (
      this.states.animateStraightLine &&
      this.states.animateStraightLine.onReset
    ) {
      this.states.animateStraightLine.onReset();
    }
  }

  /**
   * Destroys all animation controllers and removes references to them.
   * This function should be called when the TravelAnimation instance is no longer needed.
   */
  destroy() {
    // Nullify each element in the animationControllers array
    for (let i = 0; i < this.animationControllers.length; i++) {
      this.animationControllers[i]?.destroy();
      this.animationControllers[i] = undefined;
    }
  }

  /**
   * Displays a temporary text animation at the center of the map.
   *
   * This function creates a new instance of `TextAnimation` with the provided text,
   * loads the text geometry, retrieves the map center coordinates, projects them
   * into the 3D world space, positions the text plane at the center, and adds it
   * to the Three.js scene.
   */
  showTextTransition() {
    const textAnimate = new TextAnimation('Just Dev It', this.tb);
    const plane = textAnimate.loadText();
    const centerlanglat = this.map.getCenter();
    const centerlanglatarray = centerlanglat.toArray();
    const centerPosition = projectToWorld(centerlanglatarray);

    plane.position.copy(centerPosition);
    this.tb.add(plane);
  }

  /**
   * Processes travel data to prepare it for animation. This function takes an array of travel data objects
   * with decoded paths and a resource tracker that maps model enums to loaded 3D models. It iterates
   * through the travel data, retrieves the corresponding model from the tracker for each segment, and replaces
   * the `modelEnum` property with the actual model resource. This creates a new array of animation travel data
   * that is ready to be used for animation.
   *
   * @param array An array of travel data objects with decoded paths (`PublishableTravelDataWithDecodedPath[]`)
   * @param tracker A map that tracks loaded 3D models based on their model enums (`ResourceTracker`)
   * @returns A promise that resolves to an array of animation travel data (`Promise<AnimationTravelData[]>`)
   */
  async setupAnimationTravelData(
    array: PublishableTravelDataWithDecodedPath[],
    tracker: ResourceTracker,
  ): Promise<AnimationTravelData[]> {
    const updatedArray = await Promise.all(
      array.map(async (item) => {
        const key = item.travelSegmentConfig.modelEnum;
        const model = tracker.get(key);
        if (model) {
          const { modelEnum, ...restConfig } = item.travelSegmentConfig;

          // Replace model enum with model resource, which is the main difference between publishable and animation travel data
          return {
            ...item,
            travelSegmentConfig: {
              ...restConfig,
              modelsArray: model as ModelDict[],
            },
          } as AnimationTravelData;
        }
      }),
    );
    return updatedArray as AnimationTravelData[];
  }

  /**
   * Updates the animation travel data with new configuration values from the provided travel data array.
   *
   * This function assumes that the order and length of the travel data arrays are the same.
   * It updates the `modelScale` and `animationSpeed` properties within the `travelSegmentConfig`
   * of each corresponding element in the `animationTravelData` array.
   *
   * @param travelData An array of travel data objects with potentially updated configuration values
   */
  updateTravelDataWithUpdatedConfig(
    travelData: PublishableTravelDataWithDecodedPath[],
  ): void {
    console.log('updateTravelDataWithUpdatedConfig() called');
    console.log('TravelData:', travelData);
    // Ensure the new array length matches the animationTravelData array length
    if (travelData.length !== this.animationTravelData.length) {
      return;
    }

    // Update properties directly using for loop
    for (let i = 0; i < this.animationTravelData.length; i++) {
      const { modelScale, animationSpeed } = travelData[i].travelSegmentConfig;
      this.animationTravelData[i].travelSegmentConfig.modelScale = modelScale;
      this.animationTravelData[i].travelSegmentConfig.animationSpeed =
        animationSpeed;

      this.animationControllers[i]?.setup(this.animationTravelData[i], i, true);
    }
  }

  /**
   * Fetches and tracks resources (3D models) required for the travel animation.
   *
   * This function iterates through the provided travel data and identifies unique
   * model identifiers (`modelEnum`). It then uses a resource manager instance
   * to load these models asynchronously. A `ResourceTracker` map is used to keep
   * track of loaded models, preventing duplicate loading.
   *
   * The function also handles a pre-defined marker model (`MARKER_ENUM_KEY`).
   * If this marker model is present in the resource tracker, it's disposed of
   * before resolving the promise.
   *
   * @param travelData An array of travel data objects with decoded paths
   * @returns A promise that resolves with a `ResourceTracker` map containing loaded models
   */
  async setupResources(
    travelData: PublishableTravelDataWithDecodedPath[],
  ): Promise<ResourceTracker> {
    this.onResourcesLoadingStateUpdate(true);

    const resourceTracker: ResourceTracker = new Map<
      ModelKeys[],
      ModelDict[] | null
    >();

    const MARKER_ENUM_KEY_ARRAY = [MARKER_ENUM_KEY];

    // Prepopulate the map with hard-coded MARKER_ENUM_KEY i.e. marker model enum
    resourceTracker.set(MARKER_ENUM_KEY_ARRAY, null);

    // Populate the map with unique modelEnum keys from travelArray
    for (const segment of travelData) {
      resourceTracker.set(segment.travelSegmentConfig.modelEnum, null);
    }

    const resourceManager = ResourceLoader.getInstance();

    for (let modelEnum of Array.from(resourceTracker.keys())) {
      let modelsArray: ModelDict[] = [];

      for (let key of modelEnum) {
        let model: Model;
        model = await new Promise((resolve, reject) => {
          resourceManager.loadModel(key, (loadedModel) => {
            resolve(loadedModel);
          });
        });

        modelsArray.push({ key, model });
      }

      resourceTracker.set(modelEnum, modelsArray);
    }

    if (resourceTracker.get(MARKER_ENUM_KEY_ARRAY) !== null) {
      // Dispose loaded model
      this.tb.dispose(
        (resourceTracker.get(MARKER_ENUM_KEY_ARRAY) as ModelDict[])[0].model
          .scene,
      );
      // Remove key from resourceTracker
      resourceTracker.delete(MARKER_ENUM_KEY_ARRAY);
    }

    return resourceTracker;
  }

  /**
   * Resets the travel animation to a new state.
   *
   * This function takes new travel data and optionally a flag indicating
   * whether the model enums (identifiers for 3D models) have been updated.
   * Based on these inputs, it performs the following actions:
   *
   *  - If `isModelEnumUpdated` is true:
   *      - Clears the existing animation data.
   *      - Fetches resources (3D models) based on the new travel data.
   *      - Processes the new travel data to prepare for animation.
   *      - Updates the animation controllers with the new models (if applicable).
   *  - If `isModelEnumUpdated` is false:
   *      - Updates the existing animation data with configuration changes from the new travel data.
   *
   * @param travelData An array of travel data objects with decoded paths (represents the new travel data)
   * @param isModelEnumUpdated A flag indicating whether the model enums have been updated in the travel data
   * @returns A promise that resolves when the reset is complete
   */
  async reset(
    travelData: PublishableTravelDataWithDecodedPath[],
    isModelEnumUpdated: boolean,
  ): Promise<void> {
    return new Promise(async (resolve) => {
      console.log('calling this.clean()');
      this.clean();

      this.straightLineControllers.length = 0;
      this.currentSegmentIndex = clickedIndexSignal.peek() || 0;
      console.log('State machine has been reset');

      if (isModelEnumUpdated) {
        this.animationTravelData.length = 0;

        const resourceTracker = await this.setupResources(travelData);

        this.animationTravelData = await this.setupAnimationTravelData(
          travelData,
          resourceTracker,
        );

        this.onResourcesLoadingStateUpdate(false);

        for (let i = 0; i < this.animationTravelData.length; i += 1) {
          if (
            (this.animationControllers[i] as AnimationController)?.updateModel
          ) {
            (this.animationControllers[i] as AnimationController)?.updateModel(
              this.animationTravelData[i].travelSegmentConfig.modelsArray,
            );
          }
        }
      } else {
        this.updateTravelDataWithUpdatedConfig(travelData);
      }

      if (this.currentState?.isPaused) this.currentState.isPaused = false;

      resolve();

      this.setState(this.firstState);
    });
  }

  /**
   * Toggles the play and pause state of the current state machine.
   *
   * This method checks the provided `isPaused` parameter to determine whether
   * the state machine should be paused or played. If `isPaused` is true, it calls
   * the `onPause` method of the current state, if it exists. Otherwise, it calls
   * the `onPlay` method of the current state, if it exists.
   *
   * @param isPaused - A boolean indicating whether to pause (`true`) or play (`false`) the current state.
   *
   * @example
   * ```typescript
   * // Pauses the current state
   * setPlayPause(true);
   *
   * // Plays the current state
   * setPlayPause(false);
   * ```
   */
  setPlayPause(isPaused: boolean) {
    if (this.currentState) {
      if (isPaused && !this.currentState.isPaused) {
        if (this.currentState.onPause) this.currentState.onPause();
      } else if (!isPaused && this.currentState.isPaused) {
        if (this.currentState.onPlay) this.currentState.onPlay();
      }
    }
  }
}
