import { useSignalEffect } from '@preact/signals-react/runtime';
import { Map } from 'maplibre-gl';
import { useEffect, useRef, useState } from 'react';
import { mapSignal } from '~/map/ViewTravel';

import { isOverlayVisible } from './StatsOverlay';
import {
  animationIsPlaying,
  PauseAnimation,
  showPlayPauseButton,
} from './common';
import { bottomSheetOverviewData } from './MobileFooter/BottomSheet';
import { useIsMobile } from './counter/hooks/useMobile';

const useHandleMapInteractions = () => {
  const isSingleTouch = useRef(false);
  const [map, setMap] = useState<Map>();
  const isTapOnMarker = useRef(false);
  const isMobile = useIsMobile();

  useSignalEffect(() => {
    mapSignal.value && setMap(mapSignal.value);
  });

  useSignalEffect(() => {
    if (bottomSheetOverviewData.value) {
      isTapOnMarker.current = true;
    }
  });

  const onTouchStartListener = (e: any) => {
    if (isOverlayVisible.peek() && !showPlayPauseButton.peek()) {
      setTimeout(() => {
        if (!isSingleTouch.current) isOverlayVisible.value = false;
      }, 250);
    }
    if (!isSingleTouch.current) {
      if (e.originalEvent.touches.length === 1) {
        isSingleTouch.current = true;
      }
    } else {
      isSingleTouch.current = false;
    }
  };

  const onTouchMoveListener = (e: any) => {
    if (isSingleTouch.current && e.originalEvent.touches.length === 1) {
      isSingleTouch.current = false;
    }
  };

  const onTouchEndListener = () => {
    if (isSingleTouch.current) {
      setTimeout(() => {
        if (isSingleTouch.current) {
          if (isTapOnMarker.current) {
            isTapOnMarker.current = false;
            isOverlayVisible.value = true;
          } else {
            if (showPlayPauseButton.peek()) {
              if (!isOverlayVisible.peek()) isOverlayVisible.value = true;
            } else {
              isOverlayVisible.value = !isOverlayVisible.peek();
            }
          }
          showPlayPauseButton.peek() &&
            animationIsPlaying.peek() &&
            PauseAnimation();
        }
        isSingleTouch.current = false;
      }, 200);
    }
  };

  const onClick = (e: any) => {
    if (isMobile) return;
    if (isTapOnMarker.current) {
      isTapOnMarker.current = false;
      isOverlayVisible.value = true;
    } else {
      if (showPlayPauseButton.peek()) {
        if (!isOverlayVisible.peek()) isOverlayVisible.value = true;
      } else {
        isOverlayVisible.value = !isOverlayVisible.peek();
      }
    }
    showPlayPauseButton.peek() && animationIsPlaying.peek() && PauseAnimation();
  };

  useEffect(() => {
    // if (isPlaying) {
    if (map) {
      map?.on('touchstart', onTouchStartListener);
      map?.on('touchend', onTouchEndListener);
      map?.on('touchmove', onTouchMoveListener);
      map?.on('touchcancel', onTouchEndListener);
      map?.on('click', onClick);
    }
    // }
    return () => {
      map?.off('touchstart', onTouchStartListener);
      map?.off('touchend', onTouchEndListener);
      map?.off('touchmove', onTouchMoveListener);
      map?.off('touchcancel', onTouchEndListener);
      map?.on('click', onClick);
    };
  }, [map]);

  return;
};

export default useHandleMapInteractions;
