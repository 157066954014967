import React, { useState } from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { PublishableTravelData } from '~/utility/models';
import moment from 'moment-timezone';
import { uniqBy } from 'lodash';
import { motion, AnimatePresence, easeInOut } from 'framer-motion';
import { isShareModalVisible } from '../StatsOverlay';

const MobileDiscoverModal = ({
  travelPoints,
  hideModal,
}: {
  travelPoints: PublishableTravelData[];
  hideModal: () => void;
}) => {
  const userName = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const [errors, setErrors] = useState<number[]>([]);

  const images = travelPoints
    .map((i) => i.departure.images)
    .flat()
    .slice(0, 5);

  const days = uniqBy(
    travelPoints.map((i) =>
      moment(String(i.departure.dateTime)).tz(i.departure.timezone),
    ),
    (i) => i.format('DD-MM-YYYY'),
  );

  const offsets = [
    { left: '5%', top: '5%' },
    { left: '50%', top: '5%' },
    { left: '5%', top: '40%' },
    { left: '50%', top: '40%' },
    { left: '30%', top: '30%' },
  ];

  return (
    <AnimatePresence>
      <div className="mobile-discover-modal-container">
        <div className="mobile-discover-modal-user-label-container">
          <img src="./avatar.png" />
          <span>{`Made by ${userName}`}</span>
        </div>
        <div className="mobile-discover-modal-view">
          <span className="mobile-discover-modal-header-text">
            {`${days.length} Day${days.length > 1 ? 's' : ''} in ${
              travelPoints[0].arrival.location?.city
            }`}
          </span>
          {images.length > 0 && images.length !== errors.length && (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: 265,
                marginBottom: 20,
              }}
            >
              {images.map((src, idx) => {
                if (errors.includes(idx)) return null;
                return (
                  <img
                    key={idx}
                    src={src}
                    width={140}
                    height={140}
                    style={{
                      borderRadius: 24,
                      position: 'absolute',
                      left: offsets[idx].left,
                      top: offsets[idx].top,
                      transform: `rotate(${
                        (idx % 2 === 0 ? -1 : 1) * Math.random() * 15
                      }deg) scale(${(9.5 + Math.random() * 3 - 1) / 10})`,
                    }}
                    onError={() => setErrors((v) => [...v, idx])}
                    alt={`img ${idx}`}
                  />
                );
              })}
            </div>
          )}
          <span className="mobile-discover-modal-from-to">
            {`From ${days[0].format('MMMM DD, YYYY')}`}
          </span>
          <span className="mobile-discover-modal-from-to">
            {`to ${days[days.length - 1].format('MMMM DD, YYYY')}`}
          </span>
          <motion.div
            className="mobile-discover-modal-button"
            onClick={hideModal}
            animate={{
              scale: 1.05,
            }}
            exit={{ scale: 1 }}
            initial={{ scale: 1 }}
            transition={{
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 1,
              ease: 'easeInOut',
            }}
          >
            Discover your trip
          </motion.div>
          <span
            className="mobile-discover-modal-share"
            onClick={() => {
              hideModal();
              isShareModalVisible.value = true;
            }}
          >
            Share
          </span>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default MobileDiscoverModal;
