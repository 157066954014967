import { useSignalEffect } from '@preact/signals-react/runtime';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { bottomSheetOverviewData } from '../MobileFooter/BottomSheet';
import { Header, Story } from 'react-insta-stories/dist/interfaces';
import { signal } from '@preact/signals-core';
import {
  animationStateSignal,
  autoOpenImagesSignal,
  PauseAnimation,
  PlayAnimation,
  showPlayPauseButton,
} from '../common';
import { Typography } from '@mui/material';
import { getIconByCategory } from '../MobileFooter/helpers';
import { PublishableTravelData } from '~/utility/models';
const StoriesLazy = React.lazy(() => import('react-insta-stories'));

export const showStoriesSignal = signal<string[]>();

const StoriesOverlay = ({
  travelPoints,
}: {
  travelPoints: PublishableTravelData[];
}) => {
  const [stories, setStories] = useState<Story[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const isFirstTime = useRef(true);

  useSignalEffect(() => {
    if (showPlayPauseButton.value) {
      isFirstTime.current = true;
    }
  });

  const convertImagesToStories = (
    images: string[],
    label?: string,
    category?: string,
  ) => {
    return (
      images?.map((i) => ({
        url: i,
        header: {
          heading: label || bottomSheetOverviewData.value?.name || '',
          subheading: '',
          profileImage: getIconByCategory(
            category || bottomSheetOverviewData.value?.point?.category || '',
          ),
        },
      })) || []
    );
  };

  useSignalEffect(() => {
    if (showStoriesSignal.value && showStoriesSignal.value.length > 0) {
      setStories(convertImagesToStories(showStoriesSignal.value));
      setIsVisible(true);
    }
  });

  useEffect(() => {
    if (isVisible) {
      if (showPlayPauseButton.value) {
        stories.length > 0 && PauseAnimation();
        if (stories.length === 0) {
          setIsVisible(false);
        }
      }
    }
  }, [isVisible, stories]);

  useSignalEffect(() => {
    if (autoOpenImagesSignal.value > 0) {
      if (isFirstTime.current) {
        isFirstTime.current = false;
        setStories(
          convertImagesToStories(
            travelPoints[animationStateSignal.peek().calendarStep]?.departure
              .images,
            travelPoints[animationStateSignal.peek().calendarStep]?.departure
              .location?.label,
            travelPoints[animationStateSignal.peek().calendarStep]?.departure
              .category,
          ),
        );
      } else {
        setStories(
          convertImagesToStories(
            travelPoints[animationStateSignal.peek().calendarStep]?.arrival
              .images,
            travelPoints[animationStateSignal.peek().calendarStep]?.arrival
              .location?.label,
            travelPoints[animationStateSignal.peek().calendarStep]?.arrival
              .category,
          ),
        );
      }

      setIsVisible(true);
    }
  });

  if (!isVisible || stories.length === 0) {
    return null;
  }

  return (
    <div style={{ position: 'absolute', top: 0, zIndex: 99999 }}>
      <Suspense>
        <StoriesLazy
          header={(e: Header) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 8,
                  marginBottom: 24,
                  paddingLeft: 32,
                  paddingRight: 32,
                  left: -12,
                  justifyContent: 'space-between',
                  width: '100vw',
                  position: 'relative',
                }}
              >
                <img
                  src={e.profileImage}
                  height={48}
                  style={{ marginRight: 10 }}
                />
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <Typography
                    fontSize={24}
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    style={{
                      color: '#fff',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                    }}
                  >
                    {e.heading}
                  </Typography>
                </div>
              </div>
            );
          }}
          stories={stories}
          defaultInterval={3000}
          width={window.innerWidth}
          height={window.innerHeight}
          // isPaused={false}
          storyStyles={{
            width: undefined,
            height: undefined,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          onAllStoriesEnd={() => {
            showStoriesSignal.value = undefined;
            setStories([]);
            setIsVisible(false);
            if (showPlayPauseButton.value) {
              PlayAnimation();
              PlayAnimation();
              setTimeout(() => {
                PauseAnimation();
                PlayAnimation();
                PlayAnimation();
              }, 1000);
            }
          }}
        />
      </Suspense>
    </div>
  );
};

export default StoriesOverlay;
