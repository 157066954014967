import React, { useCallback, useRef, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import moment, { Moment } from 'moment-timezone';
import 'swiper/css';
import { signal } from '@preact/signals-core';
import { TravelPoint } from '../StatsOverlay';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { Swiper as SwiperType } from 'swiper/types';
import { uniqBy } from 'lodash';

export interface VizualizeButtonData {
  day: number;
  startIndex: number;
  endIndex: number;
}

export const selectedTravelDaySignal = signal<Moment | undefined>();
export const showDayHeader = signal(0);
export const vizualizeButtonSignal = signal<VizualizeButtonData | undefined>();

const DaysHeader = ({
  travelData,
  onDayPress,
  initialDay,
}: {
  travelData: TravelPoint[];
  onDayPress?: (v: Moment) => void;
  initialDay?: Moment;
}) => {
  const [selectedDay, setSelectedDay] = useState<Moment | undefined>(
    initialDay,
  );
  const swiperRef = useRef<SwiperType>();

  let travelDays = uniqBy(
    travelData.map((i, idx) => ({
      date: moment(String(i.departure.dateTime)).tz(i.departure.timezone),
      index: idx,
      timezone: i.departure.timezone,
    })),
    (u) => u.date.format('DD-MM-YYYY'),
  ).map((i) => ({
    date: i.date,
    index: i.index,
  }));

  useSignalEffect(() => {
    setSelectedDay(selectedTravelDaySignal.value);
  });

  const handleDay = (i: (typeof travelDays)[number], idx: number) => {
    if (
      !!selectedTravelDaySignal.value &&
      i.date.format('DD-MM-YYYY') ===
        selectedTravelDaySignal.value?.format('DD-MM-YYYY')
    ) {
      setSelectedDay(undefined);
      selectedTravelDaySignal.value = undefined;
      vizualizeButtonSignal.value = undefined;
    } else {
      selectedTravelDaySignal.value = i.date;
      vizualizeButtonSignal.value = {
        day: idx + 1,
        startIndex: i.index,
        endIndex: travelDays[idx + 1]
          ? travelDays[idx + 1].index - 1
          : travelData.length - 1,
      };
      setSelectedDay(i.date);
      swiperRef.current?.slideTo(idx);
    }
  };

  return (
    <Swiper
      //@ts-ignore
      onSwiper={(ref) => (swiperRef.current = ref)}
      slidesPerView={'auto'}
      spaceBetween={10}
      style={{
        paddingTop: 5,
        paddingBottom: 10,
      }}
      longSwipesRatio={1}
      centeredSlidesBounds
      centerInsufficientSlides
      centeredSlides
      onTouchStart={() => {
        showDayHeader.value += 1;
      }}
    >
      {travelDays.map((i, idx) => {
        const selected =
          i.date.format('DD-MM-YYYY') === selectedDay?.format('DD-MM-YYYY');
        return (
          <SwiperSlide style={{ width: 51 }} key={i.toString() + idx}>
            <div
              onClick={() => {
                if (typeof onDayPress === 'function') {
                  onDayPress(i.date);
                  setSelectedDay(i.date);
                } else {
                  handleDay(i, idx);
                }
              }}
              style={{
                backgroundColor: selected ? '#FE7138' : '#FF91471A',
                height: 60,
                width: 51,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                borderRadius: 12,
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {'Day'}
              </div>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {idx + 1}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default DaysHeader;
