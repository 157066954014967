import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import AccentText from './AccentText';
import {
  animationIsPlaying,
  animationStateSignal,
  currentTravelTypeSignal,
  durationString,
  getActionBySelectedTransport,
  PauseAnimation,
  showPlayPauseButton,
} from '../common';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { AnimatePresence, motion } from 'framer-motion';
import { PublishableTravelData } from '~/utility/models';
import HeaderText from './HeaderText';

import moment from 'moment-timezone';
import useTitleState from './useTitleState';
import {
  bottomSheetOverviewData,
  resetTrip,
} from '../MobileFooter/BottomSheet';
import { restartDaySignal } from '~/map/ViewTravel';
import { isOverlayVisible, isShareModalVisible } from '../StatsOverlay';
import { vizualizeButtonSignal } from '../MobileFooter/DaysHeader';
import { showDrawerMenu } from '../DrawerMenu';

const MobileHeader = React.memo(
  ({ travelPoints }: { travelPoints: PublishableTravelData[] }) => {
    const [isAnimationStarted, setIsAnimationStarted] = useState(false);
    const [isOverlay, setIsOverlay] = useState(isOverlayVisible.peek());
    const [isVisible, setIsVisible] = useState(true);
    const duration = useRef('');

    useSignalEffect(() => {
      setIsAnimationStarted(showPlayPauseButton.value);
    });

    useSignalEffect(() => {
      setIsOverlay(isOverlayVisible.value);
    });

    useSignalEffect(() => {
      setIsVisible(!bottomSheetOverviewData.value);
    });

    const { step, headerState, day } = useTitleState(travelPoints);

    const currentPoint = travelPoints[step];
    const nextPoint = travelPoints[step + 1];

    const staypointLabel = useRef<string>(
      currentPoint.departure.location?.label || '',
    );
    const isLastDayPoint = useRef(false);

    useSignalEffect(() => {
      if (vizualizeButtonSignal.value) {
        staypointLabel.current =
          travelPoints[vizualizeButtonSignal.value.startIndex || 0].departure
            .location?.label || '';
      }
    });

    useEffect(() => {
      if (headerState === 'depart') {
        staypointLabel.current = currentPoint?.arrival?.location?.label || '';
        isLastDayPoint.current = !!nextPoint
          ? !(
              moment(String(currentPoint.departure.dateTime))
                .tz(currentPoint.departure.timezone)
                .format('DD-MM-YYYY') ===
              moment(String(nextPoint?.departure?.dateTime))
                .tz(nextPoint?.departure?.timezone)
                .format('DD-MM-YYYY')
            )
          : false;
        duration.current = durationString(
          moment(String(nextPoint?.departure?.dateTime)),
          moment(String(currentPoint.arrival.dateTime)),
        );
      }
    }, [headerState, currentPoint, nextPoint]);

    return (
      <AnimatePresence>
        {isVisible && (
          <motion.div
            exit={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -200 }}
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              pointerEvents: 'none',
            }}
          >
            {isAnimationStarted && (
              <motion.div
                exit={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -100 }}
                transition={{ type: 'tween' }}
              >
                <div
                  className={`mobileHeader-container ${
                    headerState === 'showDay'
                      ? 'mobileHeader-dark-bg'
                      : 'mobileHeader-light-bg'
                  }`}
                >
                  {headerState === 'showDay' && (
                    <HeaderText color="white">
                      Your trip on <AccentText>{`Day ${day}`}</AccentText>
                    </HeaderText>
                  )}
                  {(headerState === 'firstPoint' ||
                    (headerState === 'stayPoint' && !duration.current)) && (
                    <HeaderText>{staypointLabel.current}</HeaderText>
                  )}
                  {headerState === 'depart' && (
                    <HeaderText>
                      Depart at{' '}
                      <AccentText>
                        {moment(currentPoint?.departure?.dateTime as string)
                          .tz(currentPoint?.departure?.timezone)
                          .format('hh:mma')}
                      </AccentText>
                    </HeaderText>
                  )}
                  {headerState === 'flyfor' ? (
                    <HeaderText>
                      {getActionBySelectedTransport(
                        currentTravelTypeSignal.value,
                      )}
                      {currentPoint?.selectedTransport === 'Transit'
                        ? ''
                        : ' for '}
                      {currentPoint?.selectedTransport !== 'Transit' && (
                        <AccentText>
                          {durationString(
                            moment(String(currentPoint?.arrival?.dateTime)),
                            moment(String(currentPoint?.departure?.dateTime)),
                          )}
                        </AccentText>
                      )}
                    </HeaderText>
                  ) : null}
                  {headerState === 'arrival' && (
                    <HeaderText>
                      Arrive at{' '}
                      <AccentText>
                        {moment(currentPoint?.arrival?.dateTime as string)
                          .tz(currentPoint?.arrival?.timezone)
                          .format('hh:mma')}
                      </AccentText>
                    </HeaderText>
                  )}

                  {!isLastDayPoint.current &&
                    headerState === 'stayPoint' &&
                    !!duration.current && (
                      <HeaderText>
                        Spend <AccentText>{duration.current}</AccentText>
                        {` at ${staypointLabel.current}`}
                      </HeaderText>
                    )}
                  {headerState === 'stayPoint' && isLastDayPoint.current && (
                    <HeaderText>{staypointLabel.current}</HeaderText>
                  )}
                </div>
              </motion.div>
            )}
            <AnimatePresence>
              <motion.div
                animate={{
                  opacity: isOverlay ? 1 : 0.4,
                  y: 0,
                  zIndex: 0,
                }}
                style={{
                  position: 'relative',
                  paddingLeft: 22,
                  paddingRight: 22,
                  top: 24,
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  pointerEvents: 'none',
                }}
              >
                {isAnimationStarted ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      resetTrip.value = true;
                      restartDaySignal.value = true;
                      isOverlayVisible.value = true;
                    }}
                    style={{ pointerEvents: 'all' }}
                  >
                    <img src="./goBack.png" width={50} height={35} />
                  </div>
                ) : (
                  <div
                    className={'mobile-header-burger-menu'}
                    onClick={(e) => {
                      e.stopPropagation();
                      isOverlayVisible.value = true;
                      showDrawerMenu.value = true;
                    }}
                    style={{ pointerEvents: 'all' }}
                  >
                    <img src="./icons/burger.png" width={20} height={14} />
                  </div>
                )}
                <div
                  className={'mobile-header-share-button'}
                  onClick={(e) => {
                    e.stopPropagation();
                    isOverlayVisible.value = true;
                    animationIsPlaying.value && PauseAnimation();
                    isShareModalVisible.value = true;
                  }}
                  style={{ pointerEvents: 'all' }}
                >
                  <img src="./icons/share.png" width={24} height={24} />
                  <span>Share</span>
                </div>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    );
  },
  (prev, next) => prev.travelPoints === next.travelPoints,
);

export default MobileHeader;
