import { Clock } from 'three';
import { TravelAnimation } from '../TravelAnimation';
import { State } from '../models';
import { MarkerInstances } from '~/utility/models';
import { Marker } from '~/animationEngine/Marker';
import { destinationStateSignal, durationSignal, getDestinationPointData, provokeAutoOpenImages, setImagesBySignal, TravelDataSignal } from '~/components/ViewTravel/common';

export class DestinationDelayState implements State {
  private stateMachine: TravelAnimation;
  private clock = new Clock();
  private markers: MarkerInstances | undefined;

  animationStartTime = 0;
  animationDuration = 1000;
  remainingTime = this.animationDuration;
  timeElapsed!: number;
  isPaused = false;
  intervalId: NodeJS.Timeout | null = null;

  /**
   * @constructor
   * @param stateMachine - A reference to the TravelAnimation state machine.
   */
  constructor(stateMachine: TravelAnimation) {
    this.stateMachine = stateMachine;
  }

  onEnter() {
    console.log('Entering DestinationDelayState state');
    destinationStateSignal.value = getDestinationPointData();

    provokeAutoOpenImages();

    this.markers = this.stateMachine.getCurrentController()?.markers;

    this.animationStartTime = performance.now();
    this.setIntervalWithRemainingTime();
  }

  setIntervalWithRemainingTime() {
    this.clearInterval();
    this.intervalId = setTimeout(() => {
      this.setState();
    }, this.remainingTime);
  }

  clearInterval() {
    if (this.intervalId) {
      clearTimeout(this.intervalId);
      this.intervalId = null;
    }
  }

  setState() {
    this.stateMachine.setState(this.stateMachine.states.clearPreviousTravelSegment);
  }

  onUpdate() {
    this.stateMachine.map.repaint = true;
    const delta = this.clock.getDelta();
    for (const controller of this.stateMachine.animationControllers) {
      this.markers = controller?.markers;
      for (const markerInstance of Object.values(
        this.markers as MarkerInstances,
      )) {
        (markerInstance as Marker).update(delta);
      }
    }
  }

  onPause() {
    this.isPaused = true;
    this.timeElapsed = performance.now() - this.animationStartTime;
    this.remainingTime = this.animationDuration - this.timeElapsed;
    this.clearInterval();

    console.log(this.timeElapsed, 'elapsed');
  }

  onPlay() {
    this.isPaused = false;
    this.setIntervalWithRemainingTime();

    console.log(this.remainingTime, 'remaining');
  }

  onExit() {
    if (this.stateMachine.devMode)
      console.log('Exiting AnimateCameraToOrigin state');

    this.animationStartTime = 0;
    this.animationDuration = 1000;
    this.remainingTime = this.animationDuration;
    this.timeElapsed = 0;
    this.isPaused = false;
  }
}
